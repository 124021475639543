import { useCallback, useEffect, useState } from "react";
import { ChapterDetailResponseDTO, OrgType } from "../../../../../../api/types";
import { produce } from "immer";
import {
    queryKeys,
    useGetOrgChapters,
    useGetOrgCountries,
    useGetOrgRegions
} from "../../../../../../api/queries";
import { FilterStateOperations, MenuStateOperations, OrgFilterState, OrgMenuState, OrgQueries } from "./types";
import { useQueryClient } from "react-query";
import { notEmpty } from "../../../../../../utils/misc";
import { RegistrationType } from "../../../../../../api/endpoints";

export const useOrgMenuState = (chapterDetails: ChapterDetailResponseDTO): MenuStateOperations => {

    const initialiseState = () => ({
        [OrgType.Country]: chapterDetails.countryId.toString(),
        [OrgType.Region]: chapterDetails.region.toString(),
        [OrgType.Chapter]: chapterDetails.id.toString()
    });

    const [ orgState, setOrgState ] = useState<OrgMenuState>(initialiseState);

    const getOrgState = (type: OrgType) => orgState[type];
    const resetOrgState = () => setOrgState(initialiseState());
    return {
        getOrgState,
        setOrgState,
        resetOrgState
    }
};

export const useOrgFilterState = (): FilterStateOperations => {

    const [filterState, setOrgFilterState ] = useState<OrgFilterState>( () => ({
        [OrgType.Country]: '',
        [OrgType.Region]: '',
        [OrgType.Chapter]: ''
    }))

    const getOrgFilter = (type: OrgType) => filterState[type];
    const hasOrgFilter = (type: OrgType) => notEmpty(getOrgFilter(type));
    const setOrgFilter = (type: OrgType, filter: string) => setOrgFilterState(produce(draft => {
        draft[type] = filter
    }));

    return {
        hasOrgFilter,
        getOrgFilter,
        setOrgFilter
    }

};

export const useOrgQueries = (type: RegistrationType, { getOrgState }: MenuStateOperations, { getOrgFilter }: FilterStateOperations, suppressPlannedChapters?: boolean): OrgQueries => {

    const orgCountriesQuery = useGetOrgCountries(type, getOrgFilter(OrgType.Country));
    const orgRegionsQuery = useGetOrgRegions(type, getOrgState(OrgType.Country), getOrgFilter(OrgType.Region));
    const orgChaptersQuery = useGetOrgChapters(type, getOrgState(OrgType.Region), getOrgFilter(OrgType.Chapter), {
        suppressPlannedChapters: suppressPlannedChapters
    });

    return {
        [OrgType.Country]: orgCountriesQuery,
        [OrgType.Region]: orgRegionsQuery,
        [OrgType.Chapter]: orgChaptersQuery
    }

}

export const useOrgUpdateHandler = ({ setOrgState, getOrgState }: MenuStateOperations, orgQueries: OrgQueries) => {
    const queryClient = useQueryClient();
    const updateSelectedOrg = useCallback((type: OrgType, selectedId?: string) => {
        switch(type) {
            case OrgType.Country: {
                return setOrgState({
                    [OrgType.Country]: selectedId,
                    [OrgType.Region]: undefined,
                    [OrgType.Chapter]: undefined
                })
            }
            case OrgType.Region: {
                return setOrgState(produce(draft => {
                    draft[OrgType.Region] = selectedId;
                    draft[OrgType.Chapter] = undefined;
                }));
            }
            case OrgType.Chapter: {
                queryClient.invalidateQueries(queryKeys.chapterDetails());
                return setOrgState(produce(draft => {
                    draft[OrgType.Chapter] = selectedId;
                }));
            }
        }
    }, []);

    const updateDefaultOrg = (type: OrgType) => {
        const orgData = orgQueries[type].data;
        if(orgData && orgData.length > 0 && !getOrgState(type)) {
            setOrgState(produce(draft => {
                const defaultOrg = orgData[0];
                draft[type] = defaultOrg.isSection ? orgData[1].id.toString() : orgData[0].id.toString();
            }))
        }
    };

    useEffect(() => updateDefaultOrg(OrgType.Country), [orgQueries[OrgType.Country].data])
    useEffect(() => updateDefaultOrg(OrgType.Region), [orgQueries[OrgType.Region].data])
    useEffect(() => updateDefaultOrg(OrgType.Chapter), [orgQueries[OrgType.Chapter].data])

    return updateSelectedOrg;

};

