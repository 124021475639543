import React from 'react';
import { FooterContainer, FooterLinks} from './assets/styles';
import { EuiLink, EuiText } from "@elastic/eui";
import { CommonToken, useTranslations } from "../../../../utils/translations";

const Footer = () => {
    const { t } = useTranslations();

    return (
        <FooterContainer>
            <EuiText>{t(CommonToken.Copyright)}</EuiText>
            <FooterLinks>
                <a href='http://bnitos.com/' target='_blank'>{t(CommonToken.Terms)}</a>
                <a href='http://bnitos.com/privacy.html' target='_blank'>{t(CommonToken.PrivacyPolicy)}</a>
                <a href='http://bnitos.com/browser.html' target='_blank'>{t(CommonToken.BrowserPolicy)}</a>
            </FooterLinks>
        </FooterContainer>
    )
};

export default Footer;
