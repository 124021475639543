import Styled from 'styled-components';

export const HeaderContainer = Styled.header`
    display: grid;
    grid-template-columns: 260px 350px 1fr;
    grid-template-rows: auto;
    grid-gap: 2rem;
    padding: 1rem 1.5rem;
    box-shadow: var(--shadow-200);
    background-color: var(--background-light-100);
    align-items: center;
    z-index: 2;
`;
