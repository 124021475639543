import React, { useState } from "react";
import {
    AvatarContainer,
    LogoutLink,
    MenuHeaderText,
    MenuText,
    ProfileAvatar,
    ProfilePopover,
    UserMenuContainer
} from "./assets/styles";
import { useProfile, useProfileImage } from "../../../../api/queries";
import { domain } from "../../../../utils/config";
import { CommonToken, useTranslations } from "../../../../utils/translations";

const UserAvatar = () => {

    const { t } = useTranslations();
    const profileImageQuery = useProfileImage();
    const profileQuery = useProfile();
    const [showMenu, setShowMenu] = useState(false);

    if (!profileImageQuery.isSuccess || !profileQuery.isSuccess) return null;

    const { profile: { details } } = profileQuery.data;
    const onLogout = () => {
        localStorage.removeItem("visitor:portal:session");
        localStorage.removeItem("visitor:portal:parameters");
        window.location.href = "/login/";
    };
    return (
        <AvatarContainer>
            <ProfilePopover
                isOpen={showMenu}
                closePopover={() => setShowMenu(false)}
                button={(
                    <ProfileAvatar
                        onClick={() => setShowMenu(true)}
                        name={`${details.firstName} ${details.lastName}`}
                        imageUrl={`${domain}/core-api/public/image/${profileImageQuery.data}`}
                    />
                )}>
                <UserMenuContainer>
                    <MenuHeaderText>{t(CommonToken.LoggedInAs)}</MenuHeaderText>
                    <MenuText>{`${details.firstName} ${details.lastName}`}</MenuText>
                    <LogoutLink onClick={onLogout}>{t(CommonToken.Logout)}</LogoutLink>
                </UserMenuContainer>
            </ProfilePopover>
        </AvatarContainer>
    );
};

export default UserAvatar;
