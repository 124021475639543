import Styled, { css } from 'styled-components';
import { EuiIcon } from '@elastic/eui';
import { applyDirectionalCSS } from '../../../utils/misc';

export const StyledIcon = Styled(EuiIcon)`
    ${applyDirectionalCSS(
        'rtl',
        css`
            transform: rotateY(180deg);
        `
    )};
`;
