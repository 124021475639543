import { VariantName } from '../components/VariantContext';
import { SessionDTO } from '../types';

export type CrossChapterSearchRequestDTO = {
    firstName?: string;
    lastName?: string;
    company?: string;
    pageNumber: number;
    pageSize: number;
    sort: {
        column: CrossChapterSortColumn;
        ascending: boolean;
    };
};

export enum CrossChapterSortColumn {
    Name = 'NAME',
    Chapter = 'CHAPTER',
    Company = 'COMPANY',
    Profession = 'PROFESSION',
}

export type CrossChapterSearchResponseDTO = SearchRegistrationsPaginationDTO & {
    content: Array<CrossChapterSearchResult>;
};

export type CrossChapterSearchResult = {
    idUser: number;
    firstName: string;
    lastName: string;
    company: string;
    chapterName: string;
    primaryCategory: {
        id: null | number;
        token: string;
    };
    secondaryCategory: string;
};

export type ChapterDisallowedDatesResponseDTO = { disallowedDates: Array<string> };

export type MarkAttendedStatusResponseDTO = Array<AdminVisitorRegistrationDTO>;

export type MarkAttendedStatusRequestDTO = {
    idList: Array<number>;
};

export type MarkAttendanceStatusMap = Record<string, MarkAttendanceStatus>;

export type MenuAccessForChapterResponseDTO = {
    countries: Array<MenuAccessOrgDTO>;
    regions: Array<MenuAccessOrgDTO>;
    chapters: [MenuAccessOrgPermissionsDTO];
};

export type MenuAccessOrgDTO = {
    id: number;
    name: string;
    parent_id?: number;
};

export type MenuAccessOrgPermissionsDTO = MenuAccessOrgDTO & {
    allowed_actions: MenuAllowedAction[];
};

export type ChapterMeetingFormatDTO = {
    chapterMeetingType: string;
    chapterMeetingTypeId: ChapterMeetingType;
    dates: Array<MeetingDateFormatDTO>;
};

export type MeetingDateFormatDTO = {
    meetingDate: string;
    meetingFormatId: MeetingFormatIdType;
    meetingFormat: string;
};

export enum ChapterMeetingType {
    PermOnline = 'PERM_ONLINE',
    PermInPerson = 'PERM_IN_PERSON',
    TempOnline = 'TEMP_ONLINE',
    TempHybrid = 'TEMP_HYBRID',
    PermHybrid = 'PERM_HYBRID',
}
export enum MeetingFormatIdType {
    ONLINE = 'ONLINE',
    INPERSON = 'IN_PERSON',
}
export enum MeetingFormat {
    Online = 'Onlineオンライン',
    InPerson = 'In person直接',
}

export enum MenuAllowedAction {
    AddRegistration = 'ADD_REGISTRATION',
    ViewRegistrations = 'VIEW_REGISTRATIONS',
    ManageRegistrations = 'MANAGE_REGISTRATION',
}

export type MarkAttendedRecords = MarkAttendedRequestDTO['putMarkAttendanceRequestList'];

export type MarkAttendedRequestDTO = {
    putMarkAttendanceRequestList: Array<MarkAttendanceRecord>;
};

export type MarkAttendanceRecord = {
    id: number;
    visitorNotes?: string;
};

export type MarkAttendedResponseDTO = Array<AdminVisitorRegistrationDTO>;

export type InvitedByResponseDTO = Array<InvitedByItemDTO>;

export type InvitedByItemDTO = {
    meetingDateId: number;
    personId: number;
    personName: string;
};

export type LanguageDetailsResponseDTO = {
    languageId: number;
    localeCode: string;
    descriptionKey: string;
    languageOrientation: LanguageOrientation;
};

export enum LanguageOrientation {
    LeftToRight = 'ltr',
    RightToLeft = 'rtl',
}

export type BulkDeleteRegistrationsRequestDTO = {
    idList: Array<number>;
};

export enum InvitedByOption {
    BNI = 1,
    Visitor = 2,
}

export type EditVisitorRegistrationRequestDTO = {
    id: number;
    invitedById?: number;
    invitedByNonMemberId?: number;
    followUpEmailRequired: boolean;
    individualById?: number;
    country: string;
    language: number;
    sendPvMail?: boolean;
} & CreateVisitorRegistrationRequestDTO;

export type CreateVisitorRegistrationRequestDTO = {
    orgChapterId: number;
    visitDate: string | null;
    titleId?: number;
    firstName: string;
    lastName: string;
    suffix: string;
    email: string;
    phone: string;
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    postcode: string;
    primaryCategoryId?: number;
    secondaryCategoryId?: number;
    priCategoryId?: number;
    secCategoryNationalId?: number;
    visitorSubtypeId: string | null;
    serviceText?: string;
    origin: string;
    categoryVersion: number;
    complianceChecked: string;
};

export type VisitorSubTypeResponseDTO = {
    subtypes: Array<VisitorSubTypeDTO>;
};

export enum VisitorSubTypeName {
    Visitor = 'VISITOR',
    First = 'FIRST',
    Guest = 'GUEST',
    Member = 'MEMBER',
    Repeat = 'REPEAT',
    Substitute = 'SUBSTITUTE',
}

export type VisitorSubTypeDTO = {
    description_key: string;
    id: VisitorSubTypeName;
};

export type CategoriesResponseDTO = Array<PrimarySecondaryDTO>;

export type PrimarySecondaryDTO = {
    primaryDescription: string;
    secondaryDescription: string;
    description: string;
    primaryId: number;
    secondaryId: number;
    other: boolean;
};

export type TranslationsResponseDTO = {
    translations: TranslationsMap;
};

export type TranslationsMap = Record<string, string>;

export type UserTitlesResponseDTO = IdToken[];
export type UserLanguagesResponseDTO = IdToken[];
export type CountriesResponseDTO = IdToken<string>[];

export type IdToken<IdType = number> = {
    id: IdType;
    token: string;
};

export type UserProfileImageResponseDTO = {
    contact: {
        profileImageId: {
            value: string;
        };
    };
};

export type UserProfileResponseDTO = {
    restricted: boolean;
    id: number;
    uuid: string;
    username: string;
    profile: {
        gdprOptIn: false;
        details: {
            titleId: number;
            firstName: string;
            lastName: string;
            companyName: string;
            suffix: string;
            gender: 'M' | 'F';
            taxReference: string;
            registeredTaxOffice: string;
            languageId: number;
        };
        contact: {
            emailAddress: string;
            phoneNumber: string;
            directNumber: string;
            homeNumber: string;
            mobileNumber: string;
            pagerNumber: string;
            voicemailNumber: string;
            tollfreeNumber: string;
            faxNumber: string;
            websiteUrl: string;
        };
        address: {
            main: UserProfileAddressDTO;
            billing: UserProfileAddressDTO;
        };
    };
    socialNetworks: Array<{
        id: number;
        url: string;
    }>;
};

export type UserProfileAddressDTO = {
    addressLine1: string;
    addressLine2: string;
    city: string;
    county: string;
    countryId: string;
    postcode: string;
};

export type SearchRegistrationCriteriaDTO = {
    chapterId: number;
    dateRange?: {
        from: string;
        to: string;
    };
    email?: string;
    firstName?: string;
    individualId?: number;
    individualUuid?: string;
    industry?: string;
    keyword?: string;
    lastName?: string;
    page?: {
        page_number: number;
        page_size: number;
    };
    sort?: {
        ascending: boolean;
        column: SearchRegistrationSortColumn;
    };
};

export enum SearchRegistrationSortColumn {
    Company = 'COMPANY',
    Email = 'EMAIL',
    Name = 'NAME',
    Phone = 'PHONE',
    VisitDate = 'VISIT_DATE',
}

export type AdminOrgCountriesResponseDTO = {
    countries: Array<AdminOrgDTO>;
};

export type AdminOrgRegionsResponseDTO = {
    regions: Array<AdminOrgDTO>;
};

export type AdminOrgChaptersGroupedResponseDTO = {
    chapters: Record<OrgChapterType, AdminOrgDTO[]>;
};

export type AdminOrgChaptersResponseDTO = {
    chapters: AdminOrgDTO[];
};

export type OrgDTO = {
    id: number;
    name: string;
    isSection?: boolean;
};

export type AdminOrgDTO = OrgDTO & {
    allowed_actions: AdminOrgAction[];
    parent_id?: number;
};

export enum AdminOrgAction {
    AddRegistration = 'ADD_REGISTRATION',
    ViewRegistrations = 'VIEW_REGISTRATIONS',
    ManageRegistrations = 'MANAGE_REGISTRATION',
}

export enum OrgType {
    Country = 'country',
    Region = 'region',
    Chapter = 'chapter',
}

export type ChapterDetailResponseDTO = {
    id: number;
    name: string;
    phoneNumber: string;
    meetingDay: string;
    meetingTime: string;
    locationName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    countryId: number;
    region: number;
    regionName: string;
    countryName: string;
    directions: string;
    totalMemberCount: number;
    chapterUrl: string;
    visitChapterUrl: string;
    chapterText: string;
    categoryVersion: number;
    onlineApplicationsEnabled: boolean;
    timezone: {
        id: number;
        zoneId: string;
        offset: string;
    };
    streamlineRenewalsEnabled: boolean;
    defaultLanguage: {
        languageId: number;
        localeCode: string;
        descriptionKey: string;
        languageOrientation: null | string;
    };
    language: {
        languageId: number;
        localeCode: string;
        descriptionKey: string;
        languageOrientation: null | string;
    };
    onlineMeetingLink: string;
    meetingType: string;
    chapterType: ChapterType;
    crossChapterSearch: boolean;
    meetingTypeToken: string;
};

export enum ChapterType {
    Chapter = 'CHAPTER',
    CoreGroup = 'CORE_GROUP',
    PlannedChapter = 'PLANNED_CHAPTER',
}

export enum OrgChapterType {
    Active = 'ACTIVE',
    New = 'NEW',
    Suspended = 'SUSPENDED',
    Dropped = 'DROPPED',
    CoreGroup = 'CORE_GROUP',
    DroppedCoreGroup = 'DROPPEDCOREGROUP',
    PlannedChapter = 'PLANNED_CHAPTER',
    DroppedPlannedChapter = 'DROPPEDPLANNEDCHAPTER',
}

export type AuthenticateClientParameters = {
    masterToken: string;
    variant: VariantName;
};

export type RefreshTokenParameters = {
    variant: VariantName;
    session: SessionDTO;
};

export type AuthTokenRequestDTO = {
    masterToken: string;
};

export type AuthTokenResponseDTO = {
    access_token: string;
    expires_in: number;
    refresh_token: string;
};

export type RefreshTokenRequestDTO = {
    value: string;
};

export type VisitorSignUpRegistrationDTO = {
    id: number;
    categoryVersion: number;
    companyName: string;
    firstName: string;
    language: number;
    country: string;
    lastName: string;
    orgChapterId: number;
    phone: string;
    priCategoryId?: number;
    primaryCategoryId?: number;
    secCategoryNationalId?: number;
    secondaryCategoryId?: number;
    visitDate: string;
    invitedByText: string;
    serviceText?: string;
    gdprOptIn?: 'Y' | 'N';
};

export type AdminVisitorRegistrationDTO = {
    id: number;
    addressLine1: string;
    addressLine2: string;
    capturedFirstName: string;
    capturedLastName: string;
    categoryVersion: number;
    city: string;
    classificationName: string;
    convertedToVisitor: boolean;
    country: string;
    deleted: boolean;
    email: string;
    fax: string;
    firstName: string;
    followUpEmailRequired: boolean;
    individualById: number;
    invitedById: number;
    invitedByNonMemberId: number;
    invitedByText: string;
    invited_by_display_value: string;
    language: number;
    lastName: string;
    mobile: string;
    orgChapterId: number;
    origin: string;
    originalVisitDateString: string;
    phone: string;
    postcode: string;
    priCategoryId: number;
    primaryCategoryId: number;
    reminderSent: boolean;
    secCategoryNationalId: number;
    secondaryCategoryId: number;
    serviceText: string;
    state: string;
    suffix: string;
    systemTimezone: string;
    titleId: number;
    uuidIndividual: string;
    visitDate: string;
    visitorSubtypeId: VisitorSubTypeName;
    companyName: string;
    markingAttendedStatus: MarkAttendanceStatus | null;
    pii_editable: boolean;
    suggested_subtype?: VisitorSubTypeName;
    current_category?: {
        primary_id: number;
        primary_text: string;
        secondary_id: number;
        secondary_text: string;
    } | null;
    complianceChecked: string;
    complianceMode: boolean;
};

export enum MarkAttendanceStatus {
    Complete = 'COMPLETE',
    Pending = 'PENDING',
    Timeout = 'TIMEOUT',
}

export type SearchRegistrationResultDTO = {
    id: number;
    companyName: string;
    email: string;
    fullName: string;
    industry: string;
    followUpEmailRequired: boolean;
    invitedBy: string;
    visitorSubtype: string;
    phone: string;
    primaryCategory?: string;
    secondaryCategory?: string;
    piiDiscrepancy?: {
        firstName: string;
        lastName: string;
    };
    visitDate: string;
};

export type SearchRegistrationsResponseDTO = ResponseEnvelopeDTO<SearchRegistrationsWrapperDTO>;

export type SearchRegistrationsWrapperDTO = SearchRegistrationsPaginationDTO & {
    content: Array<SearchRegistrationResultDTO>;
};

export type SearchRegistrationsPaginationDTO = {
    isFirst: boolean;
    isLast: boolean;
    page_number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
};

export type ResponseEnvelopeDTO<Response> = {
    content: Response;
};

export type UpdateSignUpRegistrationParams = {
    registrationId: string;
    registration: UpdateSignUpRegistrationRequest;
};

export type UpdateSignUpRegistrationRequest = Omit<
    VisitorSignUpRegistrationDTO,
    'id' | 'orgChapterId'
>;

export type CreateCredentialsRequestDTO = {
    emailAddress: string;
    username?: string;
    currentPassword?: string;
    newPassword: string;
    confirmPassword: string;
};

export type APIError = {
    error: {
        apiErrors: Array<{
            errorCode: string;
            message: string;
            token?: string;
        }>;
        type: string;
    };
};
