import { InitialisationParams } from '../types';

const PARAMETERS_STORAGE_KEY = 'visitor:portal:parameters';

export const saveParametersToStorage = (parameters: InitialisationParams) => {
    localStorage.setItem(PARAMETERS_STORAGE_KEY, JSON.stringify(parameters));
};

export const getParametersFromStorage = (): InitialisationParams | undefined => {
    const storageParameters = localStorage.getItem(PARAMETERS_STORAGE_KEY);
    if (!storageParameters) return undefined;
    return JSON.parse(storageParameters) as InitialisationParams;
};
