import React from 'react';
import { QueryErrorProps } from './types';
import { ErrorText, QueryErrorContainer, RetryLink } from './assets/styles';

const QueryError = ({ error, onRetry }: QueryErrorProps) => {
    return (
        <QueryErrorContainer>
            <ErrorText>{error}</ErrorText>
            <RetryLink onClick={onRetry}>Retry</RetryLink>
        </QueryErrorContainer>
    );
};

export default QueryError;
