import React, { MouseEventHandler, useCallback } from 'react';
import { ButtonContainer, ButtonContent, IconContainer } from './assets/styles';
import { ButtonProps } from './types';
import { EuiLoadingSpinner } from '@elastic/eui';

const Button = (props: ButtonProps) => {
    const {
        children,
        primary,
        loading,
        disabled,
        subdued,
        icon = null,
        onClick,
        iconPosition = 'left',
        ...others
    } = props;
    const onButtonClicked = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (e) => {
            if (disabled) return;
            onClick?.(e);
        },
        [disabled, onClick]
    );
    const iconWrapper = (
        <IconContainer $position={iconPosition}>
            {loading ? <EuiLoadingSpinner /> : icon}
        </IconContainer>
    );
    return (
        <ButtonContainer
            $disabled={disabled}
            $subdued={subdued}
            $loading={loading}
            $primary={primary}
            $iconPosition={iconPosition}
            {...others}
            onClick={onButtonClicked}>
            {iconPosition === 'left' && iconWrapper}
            <ButtonContent>{children}</ButtonContent>
            {iconPosition === 'right' && iconWrapper}
        </ButtonContainer>
    );
};

export default Button;
