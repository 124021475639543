import { useAppContext } from '../components/ApplicationContext';
import { useCallback, useMemo } from 'react';

export type TranslateFn = (key: string, defaultTranslation?: string) => string;
export const useTranslations = () => {
    const { translations } = useAppContext();
    const getTranslation = useCallback<TranslateFn>(
        (key: string, defaultTranslation?: string) => {
            return translations[key] || defaultTranslation || key;
        },
        [translations]
    );

    return useMemo(
        () => ({
            translations,
            t: getTranslation,
        }),
        [translations, getTranslation]
    );
};

export enum TokenExpression {
    Count = '{count}',
    FirstName = '{fname}',
    LastName = '{lname}',
    Total = '{total}',
    Format = '{format}',
    Date = '{date}',
    Chapter = '{chapter}',
}

export enum CommonToken {
    AppTitle = 'services.visitors.common.title',
    DateToday = 'services.visitors.common.today',
    DateYesterday = 'services.visitors.common.yesterday',
    DateTomorrow = 'services.visitors.common.tomorrow',
    Copyright = 'common.footer.copyright2010bniallrightsreserved',
    PrivacyPolicy = 'common.footer.privacypolicy',
    BrowserPolicy = 'common.footer.browserpolicy',
    Terms = 'common.footer.terms',
    OrgMenuCountries = 'services.visitors.common.filtermenu.countries',
    OrgMenuRegions = 'services.visitors.common.filtermenu.regions',
    OrgMenuChapters = 'services.visitors.common.filtermenu.chapters',
    OrgMenuFilterCountries = 'services.visitors.common.filtermenu.filtercountries',
    OrgMenuFilterRegions = 'services.visitors.common.filtermenu.filterregions',
    OrgMenuFilterChapters = 'services.visitors.common.filtermenu.filterchapters',
    OrgMenuSelectedChapter = 'services.visitors.common.filtermenu.selectedchapter',
    OrgMenuLoadingChapter = 'services.visitors.common.filtermenu.loadingchapter',
    OrgMenuResetChapter = 'services.visitors.common.filtermenu.resetchapter',
    OrgMenuSelectChapter = 'services.visitors.common.filtermenu.selectchapter',
    OrgMenuChapterActive = 'services.visitors.common.filtermenu.activechapters',
    OrgMenuChapterCoreGroup = 'services.visitors.common.filtermenu.corechapters',
    OrgMenuChapterSuspended = 'services.visitors.common.filtermenu.suspendedchapters',
    OrgMenuChapterPlanned = 'services.visitors.common.filtermenu.plannedchapters',
    LoggedInAs = 'services.visitors.common.header.loggedin',
    Logout = 'services.visitors.common.header.signout',
    Optional = 'services.visitors.common.form.optional',
    PasswordMinLengthAdvice = 'common.passwordstrengthindicator.need.length',
    PasswordInvalidCharsAdvice = 'common.passwordstrengthindicator.need.invalid.char',
    PasswordNumbersAdvice = 'common.passwordstrengthindicator.need.number',
    PasswordLowercaseAdvice = 'common.passwordstrengthindicator.need.lowercase',
    PasswordUppercaseAdvice = 'common.passwordstrengthindicator.need.uppercase',
    PasswordSpecialAdvice = 'common.passwordstrengthindicator.need.special',
    PasswordAdviceDesc = 'common.passwordstrengthindicator.need.intro',
    PIIDisableHelpText = 'operations.region.addvisitor.visitorhasmembership',
    Yes = 'common.yes',
    No = 'common.no',
    VisitorSubType = 'visitor.subtype.visitor',
    SubstituteSubType = 'visitor.subtype.substitute',
    GuestSubType = 'visitor.subtype.guest',
    MemberSubType = 'visitor.subtype.member',
    FirstSubType = 'visitor.subtype.first',
    RepeatSubType = 'visitor.subtype.repeat',
    InPerson = 'chapter.meeting.hybrid.format.inperson',
    Online = 'chapter.meeting.hybrid.format.online',
}

export enum DashboardToken {
    Heading = 'services.visitors.landing.heading',
    VisitorRegTitle = 'services.visitors.landing.action.viewregistrations.title',
    VisitorRegDescription = 'services.visitors.landing.action.viewregistrations.description',
    VisitorRegLink = 'services.visitors.landing.action.viewregistrations.link',
    AddRegTitle = 'services.visitors.landing.action.addregistration.title',
    AddRegDescription = 'services.visitors.landing.action.addregistration.description',
    AddRegLink = 'services.visitors.landing.action.addregistration.link',
    MarkAttendanceTitle = 'services.visitors.landing.action.markattendance.title',
    MarkAttendanceDescription = 'services.visitors.landing.action.markattendance.description',
    MarkAttendanceLink = 'services.visitors.landing.action.markattendance.link',
}

export enum AddRegToken {
    ValidateVisitDate = 'services.visitors.addregistration.validation.visitdate',
    PageTitle = 'services.visitors.landing.action.viewregistrations.title',
    RegisterMyself = 'services.visitors.addregistration.registermyself',
    RegisterSomeoneElse = 'services.visitors.addregistration.registersomeoneelse',
    AddSuccessMessage = 'services.visitors.addregistration.successmessage',
    AddErrorOwnChapter = 'services.visitors.addregistration.registerownchapterror',
    AddErrorMessage = 'services.visitors.addregistration.erroraddingmessage',
    ResetForm = 'services.visitors.addregistration.resetform',
    SaveRegistration = 'services.visitors.addregistration.saveregistration',
}

export enum ViewRegToken {
    PageTitle = 'services.visitors.viewregistrations.heading',
    SearchMeetingDate = 'services.visitors.viewregistrations.search.meetingdate',
    SearchPlaceholder = 'services.visitors.viewregistrations.search.placeholder',
    AddRegistration = 'services.visitors.viewregistrations.button.addregistration',
    MarkAttendance = 'services.visitors.viewregistrations.button.bulkmarkattendance',
    DeleteRecords = 'services.visitors.viewregistrations.button.bulkdelete',
    ClearSelection = 'services.visitors.viewregistrations.button.clearselection',
    HeaderRegistrant = 'services.visitors.viewregistrations.table.header.regisrant',
    HeaderCompany = 'services.visitors.viewregistrations.table.header.company',
    HeaderIndustry = 'services.visitors.viewregistrations.table.header.industry',
    HeaderVisitDate = 'services.visitors.viewregistrations.table.header.visitdate',
    HeaderPhone = 'services.visitors.viewregistrations.table.header.phone',
    HeaderEmail = 'services.visitors.viewregistrations.table.header.email',
    TableRecordCount = 'services.visitors.viewregistrations.table.recordcount',
    SelectionCount = 'services.visitors.viewregistrations.table.selectioncount',
    NoRegistrations = 'services.visitors.viewregistrations.table.noregistrations',
    NoCompany = 'services.visitors.viewregistrations.table.nocompany',
    RowsPerPage = 'services.visitors.viewregistrations.table.rowsperpage',
    RegisteredAs = 'services.visitors.viewregistrations.table.registeredas',
    EditRegistration = 'services.visitors.viewregistrations.table.edit',
    DeleteRegistration = 'services.visitors.viewregistrations.table.delete',
    EditRegistrationToolip = 'services.visitors.viewregistrations.table.edittooltip',
    DeleteRegistrationToolip = 'services.visitors.viewregistrations.table.deletetooltip',
    BulkDeleteSuccess = 'services.visitors.viewregistrations.bulkdelete.success',
    BulkDeleteError = 'services.visitors.viewregistrations.bulkdelete.error',
    BulkDeleteConfirmTitle = 'services.visitors.viewregistrations.bulkdelete.confirmation.title',
    BulkDeleteConfirmationCancel = 'services.visitors.viewregistrations.bulkdelete.confirmation.cancel',
    BulkDeleteConfirmationConfirm = 'services.visitors.viewregistrations.bulkdelete.confirmation.confirm',
    BulkDeleteConfirmationDescription = 'services.visitors.viewregistrations.bulkdelete.confirmation.description',
    BulkDeleteConfirmationConfirmText = 'services.visitors.viewregistrations.bulkdelete.confirmation.confirmtext',
    HeaderSelectionTooltip = 'services.visitors.viewregistrations.selection.filterdisabledtooltip',
    DeleteSuccess = 'services.visitors.viewregistrations.delete.success',
    DeleteError = 'services.visitors.viewregistrations.delete.error',
    DeleteConfirmTitle = 'services.visitors.viewregistrations.delete.confirmation.title',
    DeleteConfirmationCancel = 'services.visitors.viewregistrations.delete.confirmation.cancel',
    DeleteConfirmationConfirm = 'services.visitors.viewregistrations.delete.confirmation.confirm',
    DeleteConfirmationConfirmText = 'services.visitors.viewregistrations.delete.confirmation.confirmtext',
}

export enum EditRegToken {
    PageTitle = 'service.visitors.editregistration.heading',
    SaveRegistration = 'service.visitors.editregistration.button.saveregistration',
    ResetForm = 'service.visitors.editregistration.button.reset',
    RegistrationDetailsTitle = 'service.visitors.editregistration.fieldset.registrationdetails',
    OtherDetailsTitle = 'service.visitors.editregistration.fieldset.otherdetails',
    VisitorDetailsTitle = 'service.visitors.editregistration.fieldset.visitordetails',
    RegistrationChapter = 'services.visitors.addregistration.regdetails.chapterlabel',
    VisitDate = 'service.visitors.editregistration.field.visitdate',
    ChooseVisitDate = 'service.visitors.registration.field.visitdateplaceholder',
    Title = 'service.visitors.editregistration.field.title',
    TitlePlaceholder = 'service.visitors.editregistration.field.titleplaceholder',
    Suffix = 'service.visitors.editregistration.field.suffix',
    SuffixPlaceholder = 'service.visitors.editregistration.field.suffixplaceholder',
    FirstName = 'service.visitors.editregistration.field.firstname',
    FirstNamePlaceholder = 'service.visitors.editregistration.field.firstnameplaceholder',
    LastName = 'service.visitors.editregistration.field.lastname',
    LastNamePlaceholder = 'service.visitors.editregistration.field.lastnameplaceholder',
    Email = 'service.visitors.editregistration.field.email',
    EmailPlaceholder = 'service.visitors.editregistration.field.emailplaceholder',
    Phone = 'service.visitors.editregistration.field.phonenumber',
    PhonePlaceholder = 'service.visitors.editregistration.field.phonenumberplaceholder',
    Company = 'service.visitors.editregistration.field.companyname',
    CompanyPlaceholder = 'service.visitors.editregistration.field.companynameplaceholder',
    Country = 'service.visitors.editregistration.field.country',
    CountryPlaceholder = 'service.visitors.editregistration.field.countryplaceholder',
    CountryNoMatch = 'service.visitors.editregistration.field.countrynomatch',
    Language = 'service.visitors.editregistration.field.language',
    LanguagePlaceholder = 'service.visitors.editregistration.field.languageplaceholder',
    LanguageNoMatch = 'service.visitors.editregistration.field.languagenomatch',
    Address1 = 'service.visitors.editregistration.field.addressline1',
    Address1Placeholder = 'service.visitors.editregistration.field.addressline1placeholder',
    Address2 = 'service.visitors.editregistration.field.addressline2',
    Address2Placeholder = 'service.visitors.editregistration.field.addressline2placeholder',
    City = 'service.visitors.editregistration.field.city',
    CityPlaceholder = 'service.visitors.editregistration.field.cityplaceholder',
    State = 'service.visitors.editregistration.field.state',
    StatePlaceholder = 'service.visitors.editregistration.field.stateplaceholder',
    Postcode = 'service.visitors.editregistration.field.postcode',
    PostcodePlaceholder = 'service.visitors.editregistration.field.postcodeplaceholder',
    Category = 'service.visitors.editregistration.field.category',
    CategoryPlaceholder = 'service.visitors.editregistration.field.categoryplaceholder',
    CategoryNoMatch = 'service.visitors.editregistration.field.categorynomatch',
    ProductServiceDescription = 'service.visitors.editregistration.field.servicedescription',
    ProductServiceDescriptionPlaceholder = 'service.visitors.editregistration.field.servicedescriptionplaceholder',
    InvitedBy = 'service.visitors.editregistration.field.invitedby',
    InvitedByPlaceholder = 'service.visitors.editregistration.field.invitedbyentryplaceholder',
    InvitedByEntry = 'service.visitors.editregistration.field.invitedbyentry',
    InvitedByEntryPlaceholder = 'service.visitors.editregistration.field.invitedbyentryplaceholder',
    VisitorType = 'service.visitors.editregistration.field.visitortype',
    Notes = 'service.visitors.editregistration.field.notes',
    NotesPlaceholder = 'service.visitors.editregistration.field.notesplaceholder',
    FollowUpRequired = 'service.visitors.editregistration.field.followuprequired',
    FollowUpYes = 'service.visitors.editregistration.field.followupyes',
    FollowUpNo = 'service.visitors.editregistration.field.followupno',
    ValidationFollowUp = 'service.visitors.editregistration.validation.followup',
    ValidateChapter = 'service.visitors.editregistration.validation.chapter',
    ValidateFirstName = 'service.visitors.editregistration.validation.firstname',
    ValidateLastName = 'service.visitors.editregistration.validation.lastname',
    ValidateVisitDate = 'service.visitors.editregistration.validation.visitdate',
    ValidateEmailEnter = 'service.visitors.editregistration.validation.emailenter',
    ValidateEmailInvalid = 'service.visitors.editregistration.validation.emailinvalid',
    ValidatePhone = 'service.visitors.editregistration.validation.phone',
    ValidateCountry = 'service.visitors.editregistration.validation.country',
    ValidateLanguage = 'service.visitors.editregistration.validation.language',
    ValidateCategory = 'service.visitors.editregistration.validation.category',
    ValidateInvitedBy = 'service.visitors.editregistration.validation.invitedby',
    InvitedByOr = 'service.visitors.editregistration.or',
    InvitedByCrossChapter = 'service.visitors.editregistration.searchcrosschapter',
    CrossChapterTitle = 'service.visitors.editregistration.crosschaptersearch.title',
    CrossChapterClose = 'service.visitors.editregistration.crosschaptersearch.close',
    CrossChapterConfirm = 'service.visitors.editregistration.crosschaptersearch.confirm',
    CrossChapterInstructions = 'service.visitors.editregistration.crosschaptersearch.instructions',
    CrossChapterFirstName = 'service.visitors.editregistration.crosschaptersearch.criteria.firstname',
    CrossChapterFirstNamePlaceholder = 'service.visitors.editregistration.crosschaptersearch.criteria.firstnameplaceholder',
    CrossChapterLastName = 'service.visitors.editregistration.crosschaptersearch.criteria.lastname',
    CrossChapterLastNamePlaceholder = 'service.visitors.editregistration.crosschaptersearch.criteria.lastnameplaceholder',
    CrossChapterCompanyName = 'service.visitors.editregistration.crosschaptersearch.criteria.company',
    CrossChapterCompanyPlaceholder = 'service.visitors.editregistration.crosschaptersearch.criteria.companyplaceholder',
    CrossChapterSearch = 'service.visitors.editregistration.crosschaptersearch.search',
    CrossChapterTableName = 'service.visitors.editregistration.crosschaptersearch.table.header.name',
    CrossChapterTableChapter = 'service.visitors.editregistration.crosschaptersearch.table.header.chapter',
    CrossChapterTableCompany = 'service.visitors.editregistration.crosschaptersearch.table.header.company',
    CrossChapterTableProfession = 'service.visitors.editregistration.crosschaptersearch.table.header.profession',
    CrossChapterSuccess = 'service.visitors.editregistration.crosschaptersearch.success',
    CrossChapterNoMatch = 'service.visitors.editregistration.crosschaptersearch.nomatch',
    InvitedByChooseChapterMember = 'service.visitors.editregistration.choosechaptermember',
    EditSuccessMessage = 'service.visitors.editregistration.editsuccessmessage',
    EditErrorOwnChapter = 'service.visitors.editregistration.editerrorownchapter',
    EditErrorMessage = 'service.visitors.editregistration.editerrormessage',
    CurrentCategory = 'service.visitors.editregistration.currentprofession',
    CurrentSpeciality = 'service.visitors.editregistration.currentspeciality',
    InvitedByBNI = 'nonmembergroup.bni',
    InvitedByVisitor = 'nonmembergroup.visitor',
    ComplianceText = 'services.visitors.addregistration.compliance',
    ComplianceError = 'services.visitors.addregistration.validation.compliance',
}

export enum MarkAttendanceToken {
    PageTitle = 'service.visitors.markattendance.heading',
    ValidationVisitDate = 'service.visitors.markattendance.validation.visitdate',
    Attended = 'service.visitors.markattendance.attended',
    Skipped = 'service.visitors.markattendance.skipped',
    Review = 'service.visitors.markattendance.review',
    ListVisitDate = 'service.visitors.markattendance.list.visitdate',
    SkippedMessage = 'service.visitors.markattendance.skipped.message',
    IncludeRegistration = 'service.visitors.markattendance.includeregistration',
    SkipVisitor = 'service.visitors.markattendance.skip',
    SkipVisitorAndReview = 'service.visitors.markattendance.skipandreview',
    ReviewAttendance = 'service.visitors.markattendance.reviewattendance',
    NextVisitor = 'service.visitors.markattendance.nextvisitor',
    PreviousVisitor = 'service.visitors.markattendance.previousvisitor',
}

export enum ReviewAttendanceToken {
    PageTitle = 'services.visitors.reviewattendance.heading',
    Processing = 'services.visitors.reviewattendance.processing',
    FinishAttendance = 'services.visitors.reviewattendance.finishattendance',
    AllSkipped = 'services.visitors.reviewattendance.allskipped',
    NoRecords = 'services.visitors.reviewattendance.norecords',
    HeaderVisitor = 'services.visitors.reviewattendance.table.header.visitor',
    HeaderClassification = 'services.visitors.reviewattendance.table.header.classification',
    HeaderInvitedBy = 'services.visitors.reviewattendance.table.header.invitedby',
    HeaderCompany = 'services.visitors.reviewattendance.table.header.company',
    HeaderVisitDate = 'services.visitors.reviewattendance.table.header.visitdate',
    HeaderVisitorType = 'services.visitors.reviewattendance.table.header.visitortype',
    HeaderFollowUp = 'services.visitors.reviewattendance.table.header.followup',
    ProcessingTooltip = 'services.visitors.reviewattendance.processingtooltip',
    SuccessTooltip = 'services.visitors.reviewattendance.successtooltip',
    TimeoutTooltip = 'services.visitors.reviewattendance.timeouttooltip',
    NoCompany = 'services.visitors.reviewattendance.table.nocompany',
}

export enum SelfRegistrationToken {
    Tagline = 'service.visitors.registration.banner.headline',
    MarketingDescription = 'service.visitors.registration.banner.bodytext',
    MeetingTime = 'service.visitors.registration.banner.meetingtime',
    MeetingType = 'service.visitors.registration.banner.meetingtype',
    Website = 'service.visitors.registration.banner.website',
    VisitWebsite = 'service.visitors.registration.banner.visitwebsite',
    MeetingLocation = 'service.visitors.registration.banner.meetinglocation',
    AlreadyAMember = 'service.visitors.registration.header.alreadyamember',
    SignIn = 'service.visitors.registration.header.signin',
    RegisterToJoin = 'service.visitors.registration.registertojoin',
    BasicProfile = 'service.visitors.registration.basicprofile',
    FirstName = 'service.visitors.registration.field.firstname',
    FirstNamePlaceholder = 'service.visitors.registration.field.firstnameplaceholder',
    LastName = 'service.visitors.registration.field.lastname',
    LastNamePlaceholder = 'service.visitors.registration.field.lastnameplaceholder',
    CompanyName = 'service.visitors.registration.field.companyname',
    CompanyNamePlaceholder = 'service.visitors.registration.field.companynameplaceholder',
    PhoneNumber = 'service.visitors.registration.field.phonenumber',
    PhoneNumberPlaceholder = 'service.visitors.registration.field.phonenumberplaceholder',
    GDPROptIn = 'service.visitors.registration.field.gdproptin',
    VisitDetails = 'service.visitors.registration.visitdetails',
    YourVisit = 'service.visitors.registration.yourvisit',
    VisitDate = 'service.visitors.registration.field.visitdate',
    VisitDatePlaceholder = 'service.visitors.registration.field.visitdateplaceholder',
    InvitedBy = 'service.visitors.registration.field.invitedby',
    InvitedByPlaceholder = 'service.visitors.registration.field.invitedbyplaceholder',
    Profile = 'service.visitors.registration.profile',
    Register = 'service.visitors.registration.register',
    RegistrationComplete = 'service.visitors.registration.registrationcomplete',
    RegisteredInterest = 'service.visitors.registration.registeredinterest',
    CreateAccount = 'service.visitors.registration.createaccount',
    AccountCreated = 'service.visitors.registration.accountcreated',
    CreatedText = 'service.visitors.registration.createdtext',
    Login = 'service.visitors.registration.logintobniconnect',
    Password = 'service.visitors.registration.field.newpassword',
    PasswordPlaceholder = 'service.visitors.registration.field.newpasswordplaceholder',
    ConfirmPassword = 'service.visitors.registration.field.confirmpassword',
    ConfirmPasswordPlaceholder = 'service.visitors.registration.field.confirmpasswordplaceholder',
    CredentialsSetMessage = 'service.visitors.registration.credentialssetmessage',
    CredentialsSetError = 'service.visitors.registration.credentialsseterror',
    UpdatedRegistrationMessage = 'service.visitors.registration.updatedregistrationmessage',
    UpdatedRegistrationError = 'service.visitors.registration.updatedregistrationerror',
    ValidationVisitDate = 'service.visitors.registration.validation.visitdate',
    ValidationProfession = 'service.visitors.registration.validation.profession',
    ValidationFirstName = 'service.visitors.registration.validation.firstname',
    ValidationLastName = 'service.visitors.registration.validation.lastname',
    ValidationCompanyName = 'service.visitors.registration.validation.companyname',
    ValidationPhoneNumber = 'service.visitors.registration.validation.phonenumber',
    ValidationLanguage = 'service.visitors.registration.validation.language',
    ValidationCountry = 'service.visitors.registration.validation.country',
    ValidationMarketing = 'service.visitors.registration.validation.marketing',
    ValidationPasswordRequired = 'service.visitors.registration.validation.passwordrequired',
    ValidationPasswordStrength = 'service.visitors.registration.validation.passwordstrength',
    ValidationConfirmPassword = 'service.visitors.registration.validation.confirmpassword',
    ValidationPasswordMismatch = 'service.visitors.registration.validation.passwordsnotmatch',
}
