import Styled, { css } from 'styled-components';
import classNames from 'classnames';
import { applyDirectionalCSS, getDirection, isRtoL } from '../../../utils/misc';

type ButtonContainerProps = {
    $primary?: boolean;
    $loading?: boolean;
    $subdued?: boolean;
    $disabled?: boolean;
    $iconPosition: 'left' | 'right';
};
export const ButtonContainer = Styled.button.attrs<ButtonContainerProps>(
    ({ $primary, $loading, $disabled, $subdued }) => ({
        className: classNames({
            button: true,
            primary: $primary,
            loading: $loading,
            disabled: $disabled,
            subdued: $subdued,
        }),
    })
)<ButtonContainerProps>`

    --primary-button-background: var(--primary-gradient);
    --primary-button-color: white;
    --primary-button-shadow: 0 2px 8px -1px rgba(21, 25, 36, 0.32);;
    --primary-button-hover-background: var(--primary-100);
    --primary-button-hover-shadow: 0 4px 20px -2px rgba(21, 25, 36, 0.5);
    --primary-button-reflection-color: var(--primary);


    &:not(.primary) {
        --button-font-color: var(--primary);
        --button-border: 1px solid var(--primary);
        --button-hover-reflection-color: var(--dark-elevation-text-muted);
        --button-background: white;
        --button-hover-background: white;
    }

    &.subdued {
        --button-font-color: var(--text-primary);
        --button-border: 1px solid var(--background-light-300);
    }

    &.primary {
        --button-font-color: var(--fore-color);
        --button-box-shadow: var(--primary-button-shadow);
        --button-background: var(--primary-button-background);
        --button-hover-box-shadow: var(--primary-button-hover-shadow);
        --button-hover-background: var(--primary-button-hover-background);
        --button-hover-reflection-color: var(--primary-button-reflection-color);
    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;

    ${({ $iconPosition }) => {
        if (isRtoL()) {
            return css`
                padding: ${$iconPosition === 'left'
                    ? '10px 15px 10px 20px'
                    : '10px 20px 10px 15px'};
            `;
        } else {
            return css`
                padding: ${$iconPosition === 'left'
                    ? '10px 20px 10px 15px'
                    : '10px 15px 10px 20px'};
            `;
        }
    }};

    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1rem;
    border-radius: 6px;
    outline: none;
    text-decoration: none;
    letter-spacing: 0.5px;
    border: var(--button-border);
    box-shadow: var(--button-box-shadow);
    background: var(--button-background);
    transition: transform .44s ease, box-shadow .44s ease;
    color: var(--button-font-color);
    overflow: hidden;
    min-width: min-content;
    min-height: 44px;

    &:not(.disabled):not(.loading) {
        &:hover {
            box-shadow: var(--button-hover-box-shadow);
            transform: translateY(-3px);
            background: var(--button-hover-background);
            cursor: pointer;
        }
        &:hover:after {
            left: 120%;
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }
    }

    &.disabled {
        filter: brightness(0.9);
        cursor: not-allowed;
    }

    &.loading {
        cursor: wait;
    }

    &:after {
        background: var(--button-hover-reflection-color);
        content: "";
        height: 155px;
        left: -75px;
        opacity: .2;
        position: absolute;
        top: -50px;
        width: 50px;
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        transform: rotate(35deg);
        z-index: -10;
    }

`;

type IconContainerProps = { $position: 'left' | 'right' };
export const IconContainer = Styled.span.attrs<IconContainerProps>({
    className: 'button-icon',
})<IconContainerProps>`
    display: flex;
    align-items: center;

    ${({ $position }) => {
        if (isRtoL()) {
            return css`
                margin-right: ${$position === 'left' ? 0 : '5px'};
                margin-left: ${$position === 'right' ? 0 : '5px'};
            `;
        } else {
            return css`
                margin-left: ${$position === 'left' ? 0 : '5px'};
                margin-right: ${$position === 'right' ? 0 : '5px'};
            `;
        }
    }}

    > *:first-child {
        height: 24px;
        width: 24px;
    }

    > .euiIcon {
        height: 18px;
        width: 18px;
    }
`;

export const ButtonContent = Styled.span.attrs({
    className: 'button-content',
})`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
`;
