import React, { createContext, PropsWithChildren, useContext, useLayoutEffect } from 'react';

export enum VariantName {
    Connect = 'connect',
    Corporate = 'corporate',
}

type VariantContextType = {
    variant: VariantName;
    isConnect: boolean;
    isCorporate: boolean;
};

export const getVariantFromUrl = () => {
    return window.location.hostname.startsWith('cc') ? VariantName.Corporate : VariantName.Connect;
};

const getVariantContext = (): VariantContextType => {
    const variant = getVariantFromUrl();
    return {
        variant,
        isConnect: variant === VariantName.Connect,
        isCorporate: variant === VariantName.Corporate,
    };
};

const BaseVariantContext = createContext<VariantContextType>(getVariantContext());

export const useVariantContext = () => useContext(BaseVariantContext);

export const VariantContextProvider = ({ children }: PropsWithChildren<unknown>) => {
    const context = getVariantContext();

    // Add variant class to body - used for theming
    useLayoutEffect(() => {
        Object.values(VariantName).forEach((variant) => document.body.classList.remove(variant));
        document.body.classList.add(context.variant);
    }, [context]);

    return <BaseVariantContext.Provider value={context}>{children}</BaseVariantContext.Provider>;
};

export default VariantContextProvider;
