import { PropsWithChildren, ReactNode, Reducer } from 'react';
import {
    ChapterDetailResponseDTO,
    AdminVisitorRegistrationDTO,
    VisitorSubTypeName,
    MeetingDateFormatDTO,
} from '../../api/types';
import { Moment } from 'moment';
import { VisitorFormReducer } from './hooks';
import { OtherDetailsProps } from './lib/OtherDetails/types';
import { RegistrationType } from 'api/endpoints';

export enum FormMode {
    Add = 'add',
    Edit = 'edit',
}

export type VisitorRegistrationFormProps = PropsWithChildren<{
    className?: string;
    mode: FormMode;
    complianceMode?: boolean;
    reducer: VisitorFormReducer;
    maxVisitDate?: Moment;
    minVisitDate?: Moment;
    disableEmail?: boolean;
    showPiiDisableMessage?: boolean;
    optionalInvitedBy?: boolean;
    visitorTypeFilter?: OtherDetailsProps['visitorTypeFilter'];
    otherDetailsFields?: ReactNode;
    suppressPlannedChapters?: boolean;
    clearCategoryWhenLegacy?: boolean;
}>;

export type VisitorRegistrationFormState = {
    registrationType?: RegistrationType;
    id?: string;
    formErrors?: VisitFormErrors;
    chapterId?: string;
    visitDate?: Moment | null;
    titleId?: string;
    firstName?: string;
    lastName?: string;
    suffix?: string;
    emailAddress?: string;
    phoneNumber?: string;
    companyName?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    postCode?: string;
    visitorType?: VisitorSubTypeName;
    countryId?: string;
    languageId?: string;
    category?: string;
    categoryText?: string;
    productServiceDescription?: string;
    invitedByEntry?: string;
    invitedById?: string;
    invitedByText?: string;
    categoryVersion?: number;
    origin?: string;
    notes?: string;
    followUpEmailRequired?: boolean;
    individualId?: number;
    piiEditable?: boolean;
    registration?: AdminVisitorRegistrationDTO;
    complianceChecked?: string;
    complianceMode?: boolean;
    showComplianceMode?: string;
};

export enum RegistrationActionType {
    SetChapter = 'Set Chapter',
    SetVisitDate = 'Set Visit Date',
    SetTitle = 'Set Title',
    SetFirstName = 'Set First Name',
    SetLastName = 'Set Last Name',
    SetSuffix = 'Set Suffix',
    SetEmailAddress = 'Set Email Address',
    SetPhoneNumber = 'Set Phone Number',
    SetCompanyName = 'Set Company Name',
    SetAddressLine1 = 'Set Address Line 1',
    SetAddressLine2 = 'Set Address Line 2',
    SetCity = 'Set City',
    SetState = 'Set State',
    SetPostCode = 'Set Post Code',
    SetCategory = 'Set Category',
    SetVisitorType = 'Set Visitor Type',
    SetCountry = 'Set Country',
    SetLanguage = 'Set Language',
    SetProductServiceDescription = 'Set Product Service Desc',
    SetInvitedByEntry = 'Set Invited By Entry',
    SetInvitedBy = 'Set Invited By',
    SetFormErrors = 'Set Form Errors',
    ResetFormErrors = 'Reset Form Errors',
    SetCategoryVersion = 'Set Category Version',
    ResetForm = 'Reset Form',
    SetNotes = 'Set Notes',
    SetFollowUpEmailRequired = 'Set Follow Up Email Required',
    SetRegistrationType = 'Set Registration Type',
    SetComplianceChecked = 'Select Compliance',
}

export type RegistrationAction =
    | { type: RegistrationActionType.SetChapter; chapterId: string }
    | {
          type: RegistrationActionType.SetVisitDate;
          visitDate?: Moment | null;
      }
    | { type: RegistrationActionType.SetTitle; titleId: string }
    | { type: RegistrationActionType.SetFirstName; firstName: string }
    | { type: RegistrationActionType.SetLastName; lastName: string }
    | { type: RegistrationActionType.SetSuffix; suffix: string }
    | { type: RegistrationActionType.SetEmailAddress; emailAddress: string }
    | { type: RegistrationActionType.SetPhoneNumber; phoneNumber: string }
    | { type: RegistrationActionType.SetCompanyName; companyName: string }
    | { type: RegistrationActionType.SetAddressLine1; addressLine1: string }
    | { type: RegistrationActionType.SetAddressLine2; addressLine2: string }
    | { type: RegistrationActionType.SetCity; city: string }
    | { type: RegistrationActionType.SetState; state: string }
    | { type: RegistrationActionType.SetPostCode; postCode: string }
    | {
          type: RegistrationActionType.SetCategory;
          category?: string | undefined;
          categoryText?: string;
      }
    | { type: RegistrationActionType.SetVisitorType; visitorType: VisitorSubTypeName }
    | { type: RegistrationActionType.SetCountry; countryId?: string }
    | { type: RegistrationActionType.SetLanguage; languageId?: string }
    | { type: RegistrationActionType.SetProductServiceDescription; description: string }
    | { type: RegistrationActionType.SetInvitedByEntry; invitedByEntry: string }
    | {
          type: RegistrationActionType.SetInvitedBy;
          personId: string | undefined;
          personName: string | undefined;
      }
    | { type: RegistrationActionType.SetFormErrors; errors: VisitFormErrors }
    | { type: RegistrationActionType.ResetFormErrors }
    | { type: RegistrationActionType.SetCategoryVersion; version: number }
    | { type: RegistrationActionType.ResetForm; includeChapterReset?: boolean }
    | { type: RegistrationActionType.SetNotes; notes: string }
    | { type: RegistrationActionType.SetFollowUpEmailRequired; required: boolean }
    | { type: RegistrationActionType.SetRegistrationType; registrationType: RegistrationType }
    | { type: RegistrationActionType.SetComplianceChecked; complianceChecked: string };

export type VisitFormValidation = {
    isValid: boolean;
    errors: VisitFormErrors;
};

export type VisitFormErrors = {
    [K in keyof VisitorRegistrationFormState]: string[];
};
