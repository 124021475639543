import Styled, { css } from "styled-components";
import { FlexColumn, FlexRow } from "../../../../../assets/styles";
import { EuiAvatar, EuiLink, EuiPopover, EuiText } from "@elastic/eui";

export const AvatarContainer = Styled.div.attrs({
    className: "avatar"
})`
    ${FlexRow};
    justify-content: flex-end;

    .euiAvatar {
        margin-left: 15px;
    }
`;

export const ProfileAvatar = Styled(EuiAvatar).attrs({
    size: 'l'
})`

    &:hover {
        cursor: pointer;
    }

`

// @ts-ignore
export const ProfilePopover = Styled(EuiPopover).attrs({
})`
`;

export const UserMenuContainer = Styled.div`
    ${FlexColumn};
    min-width: 250px;
`;

const BaseText = css`
    font-size: 0.9rem;
`;

export const MenuHeaderText = Styled(EuiText)`
    ${BaseText};
    color: var(--text-subdued);
`;

export const MenuText = Styled(EuiText)`
    ${BaseText};
`;

export const LogoutLink = Styled(EuiLink)`
    ${BaseText};
`;
