import Styled from 'styled-components';
import { SnackbarProvider } from 'notistack';

export const Snackbar = Styled(SnackbarProvider)`
    @media only screen and (max-device-width: 600px) {
        #notistack-snackbar {
            text-align: center;
            font-size: 2.5rem !important;
        }
    }
`;
