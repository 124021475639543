import Styled, { createGlobalStyle, css } from 'styled-components';
import './theme.css';
import { EuiConfirmModal, EuiText } from '@elastic/eui';
import { applyDirectionalCSS, getDirection } from '../utils/misc';

export const GlobalStyle = createGlobalStyle`

    body {
        margin: 0;
        padding: 0;
        color: var(--text-primary);
        font-family: Arial, sans-serif !important;
        font-size: 16px;
    }

    textarea, button {
        font-family: Arial, sans-serif !important;
        font-size: 1rem !important;
    }

    body, html, #root, #root > div:first-child {
        height: 100%;
        width: 100%;
    }

    a, .euiLink {
        font-size: 1rem;
        color: var(--primary) !important;

        &:hover {
            color: var(--primary-200) !important;
        }
    }

    .euiLoadingContent__singleLineBackground {
        background: linear-gradient(to right, #ecf1f5 45%, #eaeaee 50%, #ecf1f5 55%)
    }

    .euiBasicTable {

        background-color: white;
        font-size: 14px;

        .euiTable, .euiText, .euiTableRowCell, .euiButtonEmpty, .euiButtonContent {
            font-size: 1em;
        }

        thead .euiTableHeaderCell .euiTableCellContent__text {
            font-size: 0.95rem;
        }

        .euiToolTipAnchor {
            max-width: 100%;
        }

        z-index: 0;

    }

    .euiOverlayMask--aboveHeader {
        z-index: 1300;
    }

    @media only screen and (max-device-width: 600px) {
        .euiContextMenuItem__text {
            font-size: 1.9rem;
        }
    }
`;

export const FlexRow = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const FlexRowItem = Styled.div`
    ${FlexRow};
`;

export const FlexColumn = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const FlexColumnItem = Styled.div`
    ${FlexColumn};
`;

export const FlexSelfCenter = css`
    display: flex;
    align-self: center;
`;

export const FlexCentered = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TruncatedText = css`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const TruncatedTextStart = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    direction: rtl;
    text-align: left;
`;

export const HeaderText = Styled.h4`
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 2rem;
`;

export const FieldStyle = css`
    font-size: 0.95rem;
    color: black;
    background-color: white;

    &::placeholder {
        color: var(--text-subdued);
    }
`;

export const CellText = Styled(EuiText)`
    ${TruncatedText};
`;

export const IndustryText = Styled(EuiText)`
    ${TruncatedTextStart};
`;

export const EmptyText = Styled(EuiText)`
    color: var(--text-subdued);
    text-style: italics;
`;

export const ConfirmModal = Styled(EuiConfirmModal)`

    direction: ${() => getDirection()};
    .euiModalFooter {
        ${applyDirectionalCSS(
            'rtl',
            css`
                justify-content: end;
            `
        )};
    }

`;
