import Styled from 'styled-components';

export const ApplicationLayoutContainer = Styled.div.attrs({
    className: 'app-layout',
})`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr 60px;
    height: 100%;
    background-color: var(--background-light-100);
`;

type PageLayoutProps = { $hasSubHeader: boolean };
export const PageLayoutContainer = Styled.div.attrs<PageLayoutProps>({
    className: 'page-layout',
})<PageLayoutProps>`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: ${({ $hasSubHeader }) => ($hasSubHeader ? 'auto 1fr' : '1fr')};
    height: 100%;
`;

export const PageSection = Styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
`;

export const Main = Styled.main`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const SubHeaderMountId = 'sub-header';

export const SubHeaderMount = Styled.div.attrs({
    id: SubHeaderMountId,
})`
    position: sticky;
    top: 0;
    z-index: 1000;
`;
