import React from 'react';
import { SnackbarProvider, SnackbarProviderProps, SnackbarOrigin } from 'notistack';
import { Snackbar } from '../assets/styles';

export const NotificationProvider = (props: SnackbarProviderProps) => {

    const anchorOrigin = {vertical: screen.width > 500 ? "bottom" : "top", horizontal: 'center'} as SnackbarOrigin;
    return (
        <Snackbar
            transitionDuration={{ appear: 3000 }}
            anchorOrigin={anchorOrigin}
            {...props}
        />
    );
}
