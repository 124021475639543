import { FormMode } from '../components/VisitorRegistrationForm/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
    APIError,
    ChapterDetailResponseDTO,
    ChapterType,
    IdToken,
    MenuAllowedAction,
    VisitorSubTypeDTO,
    VisitorSubTypeName,
} from '../api/types';
import { TranslateFn } from './translations';
import { EuiSuperSelectOption } from '@elastic/eui/src/components/form/super_select/super_select_control';
import { AxiosError } from 'axios';
import { SuggestedSelectOption } from '../components/SuggestedSelect/types';
import moment from 'moment';
import {
    css,
    FlattenInterpolation,
    FlattenSimpleInterpolation,
    Interpolation,
    InterpolationFunction,
    SimpleInterpolation,
    ThemeProps,
} from 'styled-components';

export const StandardDateFormat = 'YYYY-MM-DD';
export const UserShortDateFormatToken = 'common.dateformat.js.alt.shortdate';

export const isAddMode = (mode: FormMode) => mode === FormMode.Add;
export const isEditMode = (mode: FormMode) => mode === FormMode.Edit;

export const notEmpty = <Value = unknown>(value: Value): value is NonNullable<Value> => {
    return (
        value !== undefined &&
        value !== null &&
        (typeof value !== 'string' || value.trim().length > 0)
    );
};

export function useFirstMountState(): boolean {
    const isFirst = useRef(true);

    if (isFirst.current) {
        isFirst.current = false;

        return true;
    }

    return isFirst.current;
}

export const useUpdateEffect: typeof useEffect = (effect, deps) => {
    const isFirstMount = useFirstMountState();

    useEffect(() => {
        if (!isFirstMount) {
            return effect();
        }
    }, deps);
};

export function useDebounce<T>(value: T, delay: number): T {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}

export const parseWeekDay = (day: string) => {
    switch (day) {
        case 'MONDAY':
            return 1;
        case 'TUESDAY':
            return 2;
        case 'WEDNESDAY':
            return 3;
        case 'THURSDAY':
            return 4;
        case 'FRIDAY':
            return 5;
        case 'SATURDAY':
            return 6;
        default:
            return 0;
    }
};

export const emailRegex =
    // eslint-disable-next-line no-control-regex
    /^((([a-z]|\d|[!#$%&'*+-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/;

export const isPlaceholderValue = (value: string) => value === '-1';

export const isPlannedChapter = (chapterDetails: ChapterDetailResponseDTO) => {
    return chapterDetails.chapterType === ChapterType.PlannedChapter;
};

export const hasMenuPermission = (permission: MenuAllowedAction, actions?: MenuAllowedAction[]) => {
    return actions && actions.indexOf(permission) > -1;
};

export function mapIdTokenToOptions<IdType = string | number>(
    translate: TranslateFn,
    data: IdToken<IdType>[]
) {
    const options = translateIdTokenOptions(translate, data);
    return options.map((idToken) => ({
        value: String(idToken.id),
        inputDisplay: translate(idToken.token),
    })) as Array<EuiSuperSelectOption<string>>;
}

export function mapIdTokenToSuggestOptions<IdType = string | number>(
    translate: TranslateFn,
    data: IdToken<IdType>[]
) {
    const options = translateIdTokenOptions(translate, data);
    return options.map((idToken) => ({
        value: String(idToken.id),
        label: translate(idToken.token),
    })) as Array<SuggestedSelectOption<string>>;
}

function translateIdTokenOptions<IdType>(translate: TranslateFn, options: Array<IdToken<IdType>>) {
    const dataCopy = [...options];
    dataCopy.sort((token1, token2) => {
        const translated1 = translate(token1.token).toLowerCase();
        const translated2 = translate(token2.token).toLowerCase();
        return translated1.localeCompare(translated2);
    });

    return dataCopy;
}

export const filterAddEditVisitorTypes = (subType: VisitorSubTypeDTO) => {
    const validTypes = [
        VisitorSubTypeName.Visitor,
        VisitorSubTypeName.Guest,
        VisitorSubTypeName.Substitute,
    ];
    return validTypes.indexOf(subType.id) > -1;
};

export const isOwnChapterError = (error: AxiosError<APIError>) => {
    if (typeof error === 'object') {
        const errors = error?.response?.data?.error?.apiErrors;
        if (
            errors &&
            errors.length > 0 &&
            errors[0].token === 'app.error.registerPV.cannotInviteYourselfToOwnChapter'
        ) {
            return true;
        }
    }

    return false;
};

export const getUserShortDateFormat = (t: TranslateFn) => {
    return t(UserShortDateFormatToken, StandardDateFormat).toUpperCase();
};

export type FormErrors<Data> = {
    [K in keyof Data]: string[];
};

export function useFormErrors<FormData extends { errors?: FormErrors<FormData> }>(
    formState: FormData
) {
    const isInvalid = useCallback(
        (field: keyof FormData) => {
            const errors = formState.errors || ({} as FormData);
            return errors[field] !== undefined;
        },
        [formState]
    );

    const getErrorProps = useCallback(
        (field: keyof FormData) => {
            const errors = formState.errors || ({} as FormData);
            const fieldErrors = errors[field];
            if (fieldErrors) {
                return {
                    isInvalid: true,
                    error: fieldErrors,
                };
            }
            return {};
        },
        [formState]
    );

    return {
        isInvalid,
        getErrorProps,
    };
}

export const isErrorsValid = (errors: Record<string, unknown>) => {
    return Object.keys(errors).length === 0;
};

export const getCurrentISODate = () => {
    return moment(moment().toISOString());
};

type BeforeUnloadEventListener = (event: BeforeUnloadEvent) => any;
export const useBeforeUnload = (handler: BeforeUnloadEventListener) => {
    const eventListenerRef = useRef<BeforeUnloadEventListener>();

    useEffect(() => {
        eventListenerRef.current = (event) => {
            const returnValue = handler?.(event);
            // Handle legacy `event.returnValue` property
            // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
            if (typeof returnValue === 'string') {
                return (event.returnValue = returnValue);
            }
            // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`,
            // instead it requires `event.returnValue` to be set
            // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
            if (event.defaultPrevented) {
                return (event.returnValue = '');
            }
        };
    }, [handler]);

    useEffect(() => {
        const eventListener = (event: BeforeUnloadEvent) => {
            if (eventListenerRef.current) eventListenerRef.current(event);
        };
        window.addEventListener('beforeunload', eventListener);
        return () => {
            window.removeEventListener('beforeunload', eventListener);
        };
    }, []);
};

export const getDirection = () => {
    return document.querySelector(`div[dir=rtl]`) != null ? 'rtl' : 'ltr';
};

export function applyDirectionalCSS(
    direction: 'ltr' | 'rtl',
    style: FlattenInterpolation<unknown> | SimpleInterpolation
) {
    return () => {
        if (getDirection() === direction) return style;
        return undefined;
    };
}

export const isRtoL = () => getDirection() === 'rtl';
