import React, { ComponentType, useEffect } from 'react';
import Helmet from 'react-helmet';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Package from '../../../package.json';
import VariantContextProvider, { useVariantContext, VariantName } from 'components/VariantContext';
import loadable from '@loadable/component';
import Layout from '../../components/Layout';
import { LayoutProps } from '../../components/Layout/types';
import { useBootstrap } from './hooks';
import LoadingIndicator from '../../components/LoadingIndicator';
import { isBootstrapComplete, selfRegistrationParametersValid } from './utils';
import { ApplicationContextProvider, useAppContext } from '../../components/ApplicationContext';
import { NotificationProvider } from '../../components/Notifications';
import DirectionProvider from 'react-with-direction/dist/DirectionProvider';
import { GlobalStyle } from 'assets/styles';
import { RegistrationType } from '../../api/endpoints';
import { deleteAllPreferences } from '../../utils/preferences';
import { useBeforeUnload } from '../../utils/misc';
import { default as ReactFavicon } from 'react-favicon';
import { CommonToken, useTranslations } from '../../utils/translations';
const queryClient = new QueryClient();

const Dashboard = loadable(() => import('screens/VisitorDashboard'));
const Registrations = loadable(() => import('screens/VisitorRegistrations'));
const AddRegistration = loadable(() => import('screens/AddVisitorRegistration'));
const EditRegistration = loadable(() => import('screens/EditVisitorRegistration'));
const MarkAttendance = loadable(() => import('screens/MarkAttendance'));
const SelfRegistration = loadable(() => import('screens/SelfRegistration'));

const RegistrationsRouter = () => {
    return (
        <Switch>
            <Route exact path="/" component={withLayout(Dashboard)} />
            <Route exact path="/registrations" component={withLayout(Registrations)} />
            <Route
                exact
                path={`/registrations/add/${getRegTypeParam()}`}
                component={withDisabledOrg(AddRegistration)}
            />
            <Route
                exact
                path="/registrations/:id(\d+)"
                component={withDisabledOrg(EditRegistration)}
            />
            <Route path="/registrations/mark-attendance" component={MarkAttendance} />
        </Switch>
    );
};

const getRegTypeParam = () => {
    return `:type(${Object.values(RegistrationType).join('|')})?`;
};

const SelfRegistrationRouter = () => {
    const { parameters } = useAppContext();
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                path={path}
                render={(props) => {
                    if (!selfRegistrationParametersValid(parameters)) {
                        window.location.href = '/';
                        return null;
                    }
                    return <SelfRegistration {...props} />;
                }}
            />
        </Switch>
    );
};

const AppRouter = () => {
    const [status, bootstrapData] = useBootstrap();
    if (!isBootstrapComplete(status)) return <LoadingIndicator fullScreen />;
    const { parameters, languageDetails, ...otherBootstrapData } = bootstrapData!;

    return (
        <ApplicationContextProvider {...otherBootstrapData!} parameters={parameters!}>
            <DirectionProvider direction={languageDetails!.languageOrientation}>
                <Router basename={Package.homepage}>
                    <AppTitle />
                    <Switch>
                        <Route path="/register" component={SelfRegistrationRouter} />
                        <Route path="/" component={RegistrationsRouter} />
                    </Switch>
                </Router>
            </DirectionProvider>
        </ApplicationContextProvider>
    );
};

const AppTitle = () => {
    const { t } = useTranslations();
    return <Helmet title={t(CommonToken.AppTitle)} />;
};

export const withDisabledOrg = (Component: ComponentType, layoutProps?: LayoutProps) => {
    return withLayout(Component, { ...layoutProps, disableOrgSelection: true });
};

const withLayout = (Component: ComponentType, layoutProps?: LayoutProps) => {
    const ScreenComponent = () => <Layout {...layoutProps}>{<Component />}</Layout>;
    ScreenComponent.displayName = 'ScreenComponent';
    return ScreenComponent;
};

const Application = () => {
    // Delete any user preferences when the application unmounts
    useBeforeUnload(deleteAllPreferences);
    return (
        <QueryClientProvider client={queryClient}>
            <NotificationProvider>
                <VariantContextProvider>
                    <Favicon />
                    <AppRouter />
                </VariantContextProvider>
            </NotificationProvider>
            <ReactQueryDevtools />
            <GlobalStyle />
        </QueryClientProvider>
    );
};

const Favicon = () => {
    const { isConnect } = useVariantContext();
    const faviconURL = isConnect
        ? `${process.env.PUBLIC_URL}/favicon.ico`
        : `${process.env.PUBLIC_URL}/favicon_corporate.ico`;
    return <ReactFavicon url={faviconURL} />;
};

export default Application;
