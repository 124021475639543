import React, { useEffect, useMemo, useState } from "react";
import {
    FilterOrgsInput,
    FooterText,
    HeaderTextContainer,
    LoadingChapterText,
    MenuLabel,
    OrgMenuContents,
    OrgMenuFooter,
    OrgMenuPanelContainer,
    OrgMenusContainer,
    OrgPanelsContainer,
    PanelHeader,
    ResetButton,
    SelectedChapterContainer,
    SelectedChapterText
} from "./assets/styles";
import {
    FooterSelectedChapterProps,
    OrgListProps,
    OrgPanelConfigMap,
    OrgPanelProps,
    OrgSelectionFooterProps,
    OrgSelectionMenuProps
} from "./types";
import { EuiListGroup, EuiListGroupItem, EuiLoadingContent, EuiText } from "@elastic/eui";
import QueryError from "../../../../../QueryError";
import { OrgType } from "../../../../../../api/types";
import { useOrgFilterState, useOrgMenuState, useOrgQueries, useOrgUpdateHandler } from "./hooks";
import Button from "../../../../../Button";
import { useGetChapterDetails } from "../../../../../../api/queries";
import { useDebounce } from "../../../../../../utils/misc";
import { FlexColumnItem } from "assets/styles";
import { CommonToken, TranslateFn, useTranslations } from "../../../../../../utils/translations";
import { RegistrationType } from "../../../../../../api/endpoints";
import classNames from "classnames";
import Icon from "../../../../../Icon";

const OrgSelectionMenu = (props: OrgSelectionMenuProps) => {
    const { chapterDetails, onSelectChapter, type = RegistrationType.SomeoneElse, suppressPlannedChapters } = props;
    const { t } = useTranslations();
    const orgMenuState = useOrgMenuState(chapterDetails);
    const filterState = useOrgFilterState();

    const orgQueries = useOrgQueries(type, orgMenuState, filterState, suppressPlannedChapters);
    const updateSelectedOrg = useOrgUpdateHandler(orgMenuState, orgQueries);

    const panels = useMemo(() => getPanelConfig(t), [t]);

    return (
        <OrgMenusContainer>
            <OrgPanelsContainer>
                {Object.values(OrgType).map(type => {
                    return (
                        <OrgMenuPanel
                            orgQuery={orgQueries[type]}
                            onChange={updateSelectedOrg}
                            onChangeFilter={(filter) => filterState.setOrgFilter(type, filter)}
                            selectedOrg={orgMenuState.getOrgState(type)}
                            {...panels[type]}
                        />
                    );
                })}
            </OrgPanelsContainer>
            <OrgSelectionFooter
                selectedChapterId={orgMenuState.getOrgState(OrgType.Chapter)}
                onSelectChapter={onSelectChapter}
                onResetChapter={orgMenuState.resetOrgState}
            />
        </OrgMenusContainer>
    );
};

const getPanelConfig = (t: TranslateFn): OrgPanelConfigMap => ({
    [OrgType.Country]: {
        type: OrgType.Country,
        label: t(CommonToken.OrgMenuCountries),
        errorText: "Could not load countries",
        idleText: "Select an org in BNI Connect",
        placeholderText: t(CommonToken.OrgMenuFilterCountries)
    },
    [OrgType.Region]: {
        type: OrgType.Region,
        label: t(CommonToken.OrgMenuRegions),
        errorText: "Could not load regions",
        idleText: "Select a country to load regions",
        placeholderText: t(CommonToken.OrgMenuFilterRegions)
    },
    [OrgType.Chapter]: {
        type: OrgType.Chapter,
        label: t(CommonToken.OrgMenuChapters),
        errorText: "Could not load chapters",
        idleText: "Select a region to load chapters",
        placeholderText: t(CommonToken.OrgMenuFilterChapters)
    }
});

function OrgMenuPanel<Type extends OrgType>(props: OrgPanelProps<Type>) {

    const { type, orgQuery, onChange, selectedOrg, onChangeFilter, placeholderText } = props;
    const { idleText, errorText, label } = props;
    const [filterText, setFilterText] = useState("");

    const searchFilter = useDebounce(filterText, 500);
    useEffect(() => onChangeFilter(searchFilter), [searchFilter]);

    const panelContent = useMemo(() => {
        const onChangeOrg = (id: number) => onChange(type, id.toString());
        switch (orgQuery.status) {
            case "idle":
                return <EuiText>{idleText}</EuiText>;
            case "error":
                return <QueryError error={errorText} onRetry={orgQuery.refetch} />;
            case "success":
                return <OrgList orgs={orgQuery.data} selected={selectedOrg} onChange={onChangeOrg} />;
            case "loading":
                return <OrgPlaceholder />;
        }
    }, [orgQuery.status, orgQuery.data, selectedOrg, type]);

    return (
        <OrgMenuPanelContainer>
            <PanelHeader>
                <HeaderTextContainer>
                    <MenuLabel>{label}</MenuLabel>
                </HeaderTextContainer>
                <FilterOrgsInput
                    onChange={e => setFilterText(e.target.value)}
                    value={filterText}
                    placeholder={placeholderText}
                />
            </PanelHeader>
            <OrgMenuContents className={type}>
                {panelContent}
            </OrgMenuContents>
        </OrgMenuPanelContainer>
    );
};

const OrgList = ({ orgs, onChange, selected }: OrgListProps) => {
    const { t } = useTranslations();
    return (
        <EuiListGroup flush={true}>
            {orgs.map((org) => {
                const isSelected = selected && org.id.toString() === selected;
                return (
                    <EuiListGroupItem
                        key={`org-${org.id}`}
                        className={classNames({
                            selected: isSelected,
                            section: org.isSection
                        })}
                        label={org.isSection ? t(org.name) : org.name}
                        onClick={() => onChange(org.id)}
                    />
                );
            })}
        </EuiListGroup>
    );
};

const OrgSelectionFooter = ({ selectedChapterId, onSelectChapter, onResetChapter }: OrgSelectionFooterProps) => {
    const { t } = useTranslations();
    return (
        <OrgMenuFooter>
            <SelectedChapter chapterId={selectedChapterId} />
            <ResetButton onClick={onResetChapter}>
                {t(CommonToken.OrgMenuResetChapter)}
            </ResetButton>
            <Button
                disabled={!selectedChapterId}
                onClick={() => onSelectChapter(selectedChapterId!)}
                icon={<Icon type="pageSelect" />}>
                {t(CommonToken.OrgMenuSelectChapter)}
            </Button>
        </OrgMenuFooter>
    );
};

const SelectedChapter = ({ chapterId }: FooterSelectedChapterProps) => {
    const { t } = useTranslations();
    const chapterDetailsQuery = useGetChapterDetails(chapterId || "-1", {
        enabled: chapterId !== undefined
    });

    if (!chapterId) return <SelectedChapterContainer />;

    return (
        <SelectedChapterContainer>
            <FooterText>{t(CommonToken.OrgMenuSelectedChapter)}</FooterText>
            {chapterDetailsQuery.isSuccess ?
                <SelectedChapterText>{chapterDetailsQuery.data.name}</SelectedChapterText> :
                <LoadingChapterText>{t(CommonToken.OrgMenuLoadingChapter)}</LoadingChapterText>}
        </SelectedChapterContainer>
    );
};

const OrgPlaceholder = () => {
    return (
        <FlexColumnItem>
            {new Array(9).fill(null).map((item, index) => {
                return <EuiLoadingContent key={`org-placeholder-${index}`} lines={1} />;
            })}
        </FlexColumnItem>
    );
};

export default OrgSelectionMenu;
