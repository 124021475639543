import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { CommonInitParams, InitialisationParams, SelfRegisterInitParams } from '../../types';
import { EuiCallOut } from '@elastic/eui';
import { TranslationsMap, UserProfileResponseDTO } from '../../api/types';

type ApplicationContextType = {
    parameters: InitialisationParams;
    selectedChapterId: string;
    setSelectedChapter: (id: string) => void;
    localeCode: string;
    translations: TranslationsMap;
    userProfile: UserProfileResponseDTO;
    orgSelectionDisabled: boolean;
    setOrgSelectionDisabled: (disabled: boolean) => void;
};

const ApplicationContext = createContext<ApplicationContextType>({
    parameters: {},
    selectedChapterId: '-1',
    setSelectedChapter: () => {},
    localeCode: 'en',
    translations: {},
    userProfile: {} as UserProfileResponseDTO,
    orgSelectionDisabled: false,
    setOrgSelectionDisabled: () => {},
});

export const ApplicationContextProvider = (
    props: PropsWithChildren<{
        localeCode: string;
        parameters: InitialisationParams;
        translations: TranslationsMap;
        userProfile: UserProfileResponseDTO;
    }>
) => {
    const { children, userProfile, ...others } = props;
    const [orgSelectionDisabled, setOrgSelectionDisabled] = useState(false);
    const [selectedChapterId, setSelectedChapter] = useState(others.parameters.chapterId);

    if (!selectedChapterId) {
        return (
            <EuiCallOut color="danger" title="A problem has occurred">
                <p>There is no chapter selected.</p>
            </EuiCallOut>
        );
    }

    const contextValue: ApplicationContextType = {
        ...others,
        selectedChapterId,
        setSelectedChapter,
        orgSelectionDisabled,
        setOrgSelectionDisabled,
        userProfile,
    };

    return (
        <ApplicationContext.Provider value={contextValue}>{children}</ApplicationContext.Provider>
    );
};

export const useAppContext = () => {
    const appContext = useContext(ApplicationContext);
    if (appContext === undefined) throw new Error('No AppContextProvider.');
    return appContext;
};
