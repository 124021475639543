import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { domain, requestTimeout } from './config';
import { getSessionFromStorage, saveSessionToStorage } from './session';
import { getCurrentISODate, notEmpty } from './misc';
import moment from 'moment';
import { refreshToken } from '../api/endpoints';

export type HttpErrorType = AxiosError;
export type RequestConfig<RequestData = undefined> = Omit<AxiosRequestConfig, 'data'> & {
    excludeAuth?: boolean;
    data?: RequestData;
};

export type Response<Data> = AxiosResponse<Data>;

export const getDefaultHeaders = () => ({
    'Content-Type': 'application/json',
    Accept: 'application/json',
});

const instance = axios.create({
    baseURL: domain,
    timeout: requestTimeout,
    headers: getDefaultHeaders(),
});

export const request = async <RequestData, ResponseData>(
    url: string,
    config: RequestConfig<RequestData> = {}
): Promise<Response<ResponseData>> => {
    if (!config.excludeAuth && hasSessionExpired()) {
        const session = getSessionFromStorage()!;
        const refreshResponse = await refreshToken(session);
        saveSessionToStorage(refreshResponse.data.content, session.variant);
    }

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        try {
            instance.interceptors.response.use(
                (response) => response,
                (error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem('visitor:portal:session');
                        localStorage.removeItem('visitor:portal:parameters');
                        window.location.href = '/login/';
                    }
                    return;
                }
            );
            const response = await instance(mergeConfig(url, config));
            resolve(response);
        } catch (e) {
            reject(e);
        }
    });
};

export const hasSessionExpired = () => {
    const session = getSessionFromStorage();
    if (session) {
        const currentDate = getCurrentISODate();
        return currentDate.isSameOrAfter(moment(session?.expiresAt));
    }

    return false;
};

export const mergeConfig = <RequestData>(
    url: string,
    config: RequestConfig<RequestData>
): RequestConfig<RequestData> => {
    return {
        ...config,
        url: url,
        headers: {
            ...config.headers,
            ...(!config.excludeAuth ? getAuthHeaders() : {}),
        },
    };
};

const getAuthHeaders = (): HeadersInit => {
    const session = getSessionFromStorage();
    if (session) {
        return {
            Authorization: `Bearer ${session.access_token}`,
        };
    }

    return {};
};

type ParameterValueType = string | number | undefined | null;
export const buildURL = (baseURL: string, parameters: Record<string, ParameterValueType>) => {
    return Object.keys(parameters)
        .filter((param) => {
            return notEmpty(parameters[param]);
        })
        .reduce((url, param, index) => {
            const operator = index === 0 ? '?' : '&';
            return `${url}${operator}${param}=${parameters[param]}`;
        }, baseURL);
};
