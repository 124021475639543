import React from 'react';
import { LogoProps } from '../Header/types';
import { useVariantContext } from '../VariantContext';
import { ConnectLogo, CorporateLogo } from './assets/styles';

const Logo = ({ onClick }: LogoProps) => {
    const { isConnect } = useVariantContext();
    return isConnect ? <ConnectLogo onClick={onClick} /> : <CorporateLogo onClick={onClick} />;
};

export default Logo;
