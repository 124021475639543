import { buildURL, request, Response } from '../utils/http';
import {
    AdminOrgChaptersGroupedResponseDTO,
    AdminOrgChaptersResponseDTO,
    AdminOrgCountriesResponseDTO,
    AdminOrgDTO,
    AdminOrgRegionsResponseDTO,
    AdminVisitorRegistrationDTO,
    AuthenticateClientParameters,
    AuthTokenRequestDTO,
    AuthTokenResponseDTO,
    BulkDeleteRegistrationsRequestDTO,
    CategoriesResponseDTO,
    ChapterDetailResponseDTO,
    ChapterDisallowedDatesResponseDTO,
    CountriesResponseDTO,
    CreateCredentialsRequestDTO,
    CreateVisitorRegistrationRequestDTO,
    CrossChapterSearchRequestDTO,
    CrossChapterSearchResponseDTO,
    EditVisitorRegistrationRequestDTO,
    InvitedByResponseDTO,
    LanguageDetailsResponseDTO,
    MarkAttendedRecords,
    MarkAttendedRequestDTO,
    MarkAttendedResponseDTO,
    MarkAttendedStatusRequestDTO,
    MarkAttendedStatusResponseDTO,
    MenuAccessForChapterResponseDTO,
    OrgChapterType,
    OrgDTO,
    RefreshTokenRequestDTO,
    ResponseEnvelopeDTO,
    SearchRegistrationCriteriaDTO,
    SearchRegistrationsResponseDTO,
    TranslationsResponseDTO,
    UpdateSignUpRegistrationRequest,
    UserLanguagesResponseDTO,
    UserProfileImageResponseDTO,
    UserProfileResponseDTO,
    UserTitlesResponseDTO,
    VisitorSignUpRegistrationDTO,
    VisitorSubTypeResponseDTO,
} from './types';
import {
    connectClientId,
    connectClientSecret,
    corporateClientId,
    corporateClientSecret,
} from '../utils/config';
import { notEmpty } from '../utils/misc';
import produce from 'immer';
import { VariantName } from '../components/VariantContext';
import { CommonToken } from '../utils/translations';
import { SessionDTO } from '../types';
import { ChapterMeetingFormatDTO } from './types';

export const getBasicAuthenticationHeader = (variant: VariantName) => {
    const basicCredentials =
        variant === VariantName.Connect
            ? btoa(`${connectClientId}:${connectClientSecret}`)
            : btoa(`${corporateClientId}:${corporateClientSecret}`);
    return {
        Authorization: `Basic ${basicCredentials}`,
    };
};

export const authenticateClient = (parameters: AuthenticateClientParameters) => {
    const url = '/auth-api/authenticate-token';

    return request<AuthTokenRequestDTO, ResponseEnvelopeDTO<AuthTokenResponseDTO>>(url, {
        excludeAuth: true,
        method: 'POST',
        headers: getBasicAuthenticationHeader(parameters.variant),
        data: {
            masterToken: parameters.masterToken,
        },
    });
};

export const refreshToken = (session: SessionDTO) => {
    return request<RefreshTokenRequestDTO, ResponseEnvelopeDTO<AuthTokenResponseDTO>>(
        '/auth-api/refresh-token',
        {
            method: 'POST',
            headers: getBasicAuthenticationHeader(session.variant),
            excludeAuth: true,
            data: {
                value: session.refresh_token,
            },
        }
    );
};

export const getChapterDetails = async (chapterId: string) => {
    const url = buildURL('/core-api/chapter-detail', {
        'chapter-id': chapterId,
    });
    return request<void, ResponseEnvelopeDTO<ChapterDetailResponseDTO>>(url);
};

export const getOrgCountries = async (type: RegistrationType, filter?: string) => {
    const url =
        type === RegistrationType.SomeoneElse
            ? '/visitor/v1/admin/orgmenuaccess/countries'
            : '/visitor/v1/member/availableorg/countries';

    const response = await request<void, ResponseEnvelopeDTO<AdminOrgCountriesResponseDTO>>(url);

    if (response.status === 200 && response.data) {
        return {
            ...response,
            data: produce(response.data, (draft) => {
                draft.content.countries.sort(sortOrgsAlphabetically);
                draft.content.countries = filterAdminOrgs(draft.content.countries, filter);
            }),
        };
    }

    return response;
};

export const getOrgRegions = async (type: RegistrationType, countryId: string, filter?: string) => {
    const url =
        type === RegistrationType.SomeoneElse
            ? `/visitor/v1/admin/orgmenuaccess/country/${countryId}/regions`
            : `/visitor/v1/member/availableorg/country/${countryId}/regions`;

    const response = await request<void, ResponseEnvelopeDTO<AdminOrgRegionsResponseDTO>>(url);

    if (response.status === 200 && response.data) {
        return {
            ...response,
            data: produce(response.data, (draft) => {
                draft.content.regions.sort(sortOrgsAlphabetically);
                draft.content.regions = filterAdminOrgs(draft.content.regions, filter);
            }),
        };
    }
    return response;
};

export const getOrgChapters = async (
    type: RegistrationType,
    regionId: string,
    filter?: string,
    suppressPlannedChapters?: boolean
): Promise<Response<ResponseEnvelopeDTO<AdminOrgChaptersResponseDTO>>> => {
    const url =
        type === RegistrationType.SomeoneElse
            ? `/visitor/v1/admin/orgmenuaccess/region/${regionId}/chapters`
            : `/visitor/v1/member/availableorg/region/${regionId}/chapters`;

    const response = await request<void, ResponseEnvelopeDTO<AdminOrgChaptersGroupedResponseDTO>>(
        url
    );

    const isSuccessful = response.status === 200 && response.data;
    const chapterList = isSuccessful
        ? flattenChaptersToListOrg(response.data.content.chapters, filter, suppressPlannedChapters)
        : [];

    return {
        ...response,
        data: {
            content: {
                chapters: chapterList,
            },
        },
    };
};

const flattenChaptersToListOrg = (
    chapters: AdminOrgChaptersGroupedResponseDTO['chapters'],
    filter?: string,
    suppressPlannedChapters?: boolean
): AdminOrgDTO[] => {
    return orgChapterSections
        .filter((section) => {
            return !(section.type === OrgChapterType.PlannedChapter && suppressPlannedChapters);
        })
        .reduce((accumulator, section) => {
            const chapterList: AdminOrgDTO[] = [];
            const sectionChapters = chapters[section.type];
            if (sectionChapters) {
                chapterList.push({
                    id: section.id,
                    name: section.label,
                    isSection: true,
                    allowed_actions: [],
                });

                const copyChapters = [...(sectionChapters || [])];
                copyChapters.sort(sortOrgsAlphabetically);
                chapterList.push(...filterAdminOrgs(copyChapters, filter));
            }

            return [...accumulator, ...chapterList];
        }, [] as AdminOrgDTO[]);
};

const orgChapterSections = [
    { type: OrgChapterType.Active, id: -1, label: CommonToken.OrgMenuChapterActive },
    { type: OrgChapterType.CoreGroup, id: -2, label: CommonToken.OrgMenuChapterCoreGroup },
    { type: OrgChapterType.Suspended, id: -3, label: CommonToken.OrgMenuChapterSuspended },
    { type: OrgChapterType.PlannedChapter, id: -4, label: CommonToken.OrgMenuChapterPlanned },
];

const sortOrgsAlphabetically = (org1: OrgDTO, org2: OrgDTO) => {
    return org1.name.toLowerCase().localeCompare(org2.name.toLowerCase());
};

const filterAdminOrgs = <OrgType extends OrgDTO>(orgs: OrgType[], filter?: string) => {
    if (notEmpty(filter)) {
        return orgs.filter((org) => {
            return org.name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
        });
    }

    return orgs;
};

export const searchVisitorRegistrations = (criteria: SearchRegistrationCriteriaDTO) => {
    const url = '/visitor/v1/admin/visitor-registration/search';
    return request<SearchRegistrationCriteriaDTO, SearchRegistrationsResponseDTO>(url, {
        method: 'POST',
        data: criteria,
    });
};

export const getUserProfile = () => {
    return request<void, ResponseEnvelopeDTO<UserProfileResponseDTO>>('/core-api/portal/profile');
};

export const getUserProfileImage = () => {
    return request<void, ResponseEnvelopeDTO<UserProfileImageResponseDTO>>('/core-api/profile');
};

export const getAdminVisitorRegistration = (registrationId: string) => {
    return request<void, ResponseEnvelopeDTO<AdminVisitorRegistrationDTO>>(
        `/visitor/v1/admin/visitor-registration/${registrationId}`
    );
};

export const getVisitorSignupRegistration = (registrationId: string) => {
    return request<void, ResponseEnvelopeDTO<VisitorSignUpRegistrationDTO>>(
        `/visitor/v1/visitor-signup/registration/${registrationId}`
    );
};

export const updateVisitorSignupRegistration = (
    registrationId: string,
    registration: UpdateSignUpRegistrationRequest
) => {
    return request<
        UpdateSignUpRegistrationRequest,
        ResponseEnvelopeDTO<VisitorSignUpRegistrationDTO>
    >(`/visitor/v1/visitor-signup/registration/${registrationId}`, {
        method: 'PUT',
        data: registration,
    });
};

export const getUserTitles = () => {
    return request<void, ResponseEnvelopeDTO<UserTitlesResponseDTO>>('/core-api/data/titles');
};

export const getUserLanguages = () => {
    return request<void, ResponseEnvelopeDTO<UserLanguagesResponseDTO>>('/core-api/data/languages');
};

export const getCountries = () => {
    return request<void, ResponseEnvelopeDTO<CountriesResponseDTO>>('/core-api/data/countries');
};

export const getTranslations = (localeCode?: string) => {
    const url = buildURL('/visitor/v1/translations', {
        locale: localeCode,
    });

    return request<void, ResponseEnvelopeDTO<TranslationsResponseDTO>>(url);
};

export const getCategories = (countryId: number, locale?: string) => {
    const url = buildURL('/core-api/categories/primarySecondary/countryversion', {
        countryId: countryId,
        locale,
    });
    return request<void, ResponseEnvelopeDTO<CategoriesResponseDTO>>(url);
};

export const getVisitorSubTypes = () => {
    return request<void, ResponseEnvelopeDTO<VisitorSubTypeResponseDTO>>(
        '/visitor/v1/visitor-subtype'
    );
};

export enum RegistrationType {
    Myself = 'myself',
    SomeoneElse = 'someone_else',
}

export const createVisitorRegistration = (
    userProfile: UserProfileResponseDTO,
    registration: CreateVisitorRegistrationRequestDTO
) => {
    const registrationType = getRegistrationType(userProfile, registration);
    const registerURL =
        registrationType === RegistrationType.Myself
            ? '/visitor/v1/member/visitor-registration'
            : '/visitor/v1/admin/visitor-registration';

    return request<
        CreateVisitorRegistrationRequestDTO,
        ResponseEnvelopeDTO<AdminVisitorRegistrationDTO>
    >(registerURL, {
        method: 'POST',
        data: registration,
    });
};

export const editVisitorRegistration = (
    userProfile: UserProfileResponseDTO,
    registration: EditVisitorRegistrationRequestDTO
) => {
    return request<
        EditVisitorRegistrationRequestDTO,
        ResponseEnvelopeDTO<AdminVisitorRegistrationDTO>
    >(`/visitor/v1/admin/visitor-registration/${registration.id}`, {
        method: 'PUT',
        data: registration,
    });
};

export const bulkDeleteRegistrations = (registrationIds: Array<number>) => {
    return request<BulkDeleteRegistrationsRequestDTO, ResponseEnvelopeDTO<void>>(
        '/visitor/v1/admin/visitor-registration',
        {
            method: 'DELETE',
            data: {
                idList: registrationIds,
            },
        }
    );
};

export const getLanguageDetails = (localeCode: string) => {
    const url = `/core-api/public/languages/${localeCode}`;
    return request<void, ResponseEnvelopeDTO<LanguageDetailsResponseDTO>>(url);
};

export const getChapterInvitedBy = (chapterId: string) => {
    const url = buildURL('/visitor/v1/visitor-chapter/invited-by', {
        chapter_id: chapterId,
    });
    return request<void, ResponseEnvelopeDTO<InvitedByResponseDTO>>(url);
};

export const bulkEditVisitorRegistrations = (
    userProfile: UserProfileResponseDTO,
    registrations: Array<EditVisitorRegistrationRequestDTO>
) => {
    return Promise.all(
        registrations.map((registration) => editVisitorRegistration(userProfile, registration))
    );
};

export const getRegistrationType = (
    userProfile: UserProfileResponseDTO,
    registration: EditVisitorRegistrationRequestDTO | CreateVisitorRegistrationRequestDTO
) => {
    const emailAddress = userProfile.profile.contact.emailAddress.trim().toLowerCase();
    return emailAddress === registration.email.trim().toLowerCase()
        ? RegistrationType.Myself
        : RegistrationType.SomeoneElse;
};

export const markRegistrationAttended = (markAttendanceRecords: MarkAttendedRecords) => {
    const url = '/visitor/v1/admin/visitor-registration/mark-attended';
    return request<MarkAttendedRequestDTO, ResponseEnvelopeDTO<MarkAttendedResponseDTO>>(url, {
        method: 'PUT',
        data: { putMarkAttendanceRequestList: markAttendanceRecords },
    });
};

export const getChapterMenuAccess = (chapterId: string) => {
    const url = buildURL('/visitor/v1/admin/menuaccessforchapter', {
        chapter_id: chapterId,
    });
    return request<void, ResponseEnvelopeDTO<MenuAccessForChapterResponseDTO>>(url);
};

export const getMeetingFormat = (
    chapterId?: string,
    month?: number,
    year?: number,
    locale?: string
) => {
    const url = `/core-api/public/chapters/${chapterId}/meeting-format?month=${month}&year=${year}&locale=${locale}`;
    return request<void, ResponseEnvelopeDTO<ChapterMeetingFormatDTO>>(url, {
        excludeAuth: true,
    });
};

export const getMarkAttendanceStatus = (registrationIds: Array<number>) => {
    const url = `/visitor/v1/admin/visitor-registration/update-marking-status`;
    return request<
        MarkAttendedStatusRequestDTO,
        ResponseEnvelopeDTO<MarkAttendedStatusResponseDTO>
    >(url, {
        method: 'PUT',
        data: { idList: registrationIds },
    });
};

export const getChapterDisallowedDates = (chapterId: string) => {
    const url = `/member-api/chapter/${chapterId}/meetings/disalloweddates`;
    return request<void, ResponseEnvelopeDTO<ChapterDisallowedDatesResponseDTO>>(url, {
        excludeAuth: true,
    });
};

export const searchCrossChapter = (chapterId: string, criteria: CrossChapterSearchRequestDTO) => {
    const url = buildURL(`/visitor/v1/visitor-chapter/cross-chapter/search`, {
        chapter_id: chapterId,
    });
    return request<
        CrossChapterSearchRequestDTO,
        ResponseEnvelopeDTO<CrossChapterSearchResponseDTO>
    >(url, {
        method: 'POST',
        data: criteria,
    });
};

export const createCredentials = (credentials: CreateCredentialsRequestDTO) => {
    return request<CreateCredentialsRequestDTO, ResponseEnvelopeDTO<unknown>>(
        `/core-api/portal/credentials`,
        {
            method: 'PUT',
            data: credentials,
        }
    );
};
