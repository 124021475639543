import { HeaderContainer } from './assets/styles';
import OrgSelection from './lib/OrgSelection';
import UserAvatar from './lib/UserAvatar';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../ApplicationContext';
import Logo from 'components/Logo';
import { HeaderProps } from './types';

const Header = ({ disableOrgSelection }: HeaderProps) => {
    const history = useHistory();
    const appContext = useAppContext();
    const { selectedChapterId, setSelectedChapter, orgSelectionDisabled } = appContext;

    return (
        <HeaderContainer>
            <Logo onClick={() => history.replace('/')} />
            <OrgSelection
                disabled={disableOrgSelection || orgSelectionDisabled}
                chapterId={selectedChapterId}
                onChangeChapter={setSelectedChapter}
            />
            <UserAvatar />
        </HeaderContainer>
    );
};

export default Header;
