import ConnectLogoImage from './bni_logo.svg';
import CorporateLogoImage from './corporate_logo.png';
import Styled, { css } from 'styled-components';
import { FlexColumn } from '../../../assets/styles';

export const LogoContainer = Styled.div.attrs({
    className: 'logo',
})`
    ${FlexColumn};
    align-items: unset;
    position: relative;
`;

const LogoStyles = css`
    width: 90%;
    height: 90%;
    max-height: 50px;
    &:hover {
        cursor: pointer;
    }
`;

export const ConnectLogo = Styled.img.attrs({
    className: 'logo',
    src: ConnectLogoImage,
})`
    ${LogoStyles};
`;

export const CorporateLogo = Styled.img.attrs({
    className: 'logo',
    src: CorporateLogoImage,
})`
    ${LogoStyles};
    height: 57px;
    width: 170px;
`;
