import Styled, { css } from "styled-components";
import { FlexColumn, FlexRow, FlexSelfCenter, TruncatedText } from "../../../../../assets/styles";
import { EuiModal, EuiPopover, EuiText } from "@elastic/eui";

type OrgSelectionContainerProps = { $open?: boolean, $disabled?: boolean };
export const OrgSelectionContainer = Styled.button.attrs<OrgSelectionContainerProps>({
    className: 'org-selection'
})<OrgSelectionContainerProps>`
    ${FlexSelfCenter};
    ${FlexRow};
    font-size: 0.85rem;
    border-radius: 6px;
    box-shadow: var(--shadow-100);
    border: 1px solid var(--background-light-300);
    overflow: hidden;
    padding: 0.5rem 0.8rem;
    width: 100%;
    background-color: white;

    ${({ $open }) => {
        if($open) {
            return css`
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

            `;
        }

        return '';
    }};

    ${({ $disabled }) => {
        if($disabled) {
            return css`
                pointer-events: none;
                opacity: 0.6;
            `;
        }

        return '';
    }};

    &:hover {
        cursor: pointer;
        box-shadow: var(--shadow-200);
    }

    .euiIcon {
        width: 10%;
        ${FlexRow};
    }
`;

export const OrgDetails = Styled.div.attrs({
    className: 'org-details'
})`
    ${FlexColumn};
    width: 90%;
`;

export const ChapterName = Styled(EuiText)`
    font-size: 1.1em;
    font-weight: 500;
    line-height: 1.3rem;
    text-align: start;
    color: black;
    font-weight: 500;
    ${TruncatedText};
`;

export const RegionName = Styled(EuiText)`
    font-size: 0.9em;
    line-height: 1rem;
    color: var(--text-secondary);
    text-align: start;
    ${TruncatedText};
`;

// @ts-ignore
export const OrgSelectionPopover = Styled(EuiPopover).attrs({
    anchorPosition: 'upLeft',
    hasArrow: false,
    panelPaddingSize: 'none'
})`
    &, .euiPopover__anchor {
        width: 100%;
    }

`;

export const OrgSelectionModal = Styled(EuiModal)`
    max-width: unset;

    .euiModal__flex {
        max-height: unset;
    }
`;
