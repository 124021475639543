import React from 'react';
import { PageLayoutContainer, PageSection, SubHeaderMount } from './assets/styles';
import { PageLayoutProps } from './types';

const PageLayout = ({ className, children, subHeader, hasSubHeader }: PageLayoutProps) => {
    return (
        <PageLayoutContainer
            className={className}
            $hasSubHeader={hasSubHeader || subHeader !== undefined}>
            <SubHeaderMount>{subHeader}</SubHeaderMount>
            <PageSection>{children}</PageSection>
        </PageLayoutContainer>
    );
};

export default PageLayout;
