import Styled from 'styled-components';
import { EuiLoadingSpinner } from '@elastic/eui';

export const StyledLoadingSpinner = Styled(EuiLoadingSpinner)`

`;

export const FullScreenWrapper = Styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .euiLoadingSpinner {
        height: 75px;
        width: 75px;
    }
`;
