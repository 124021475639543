import { InitialisationParams, SessionClaims, SessionDTO, SessionOptions } from '../types';
import { useAuthTokenMutation } from '../api/queries';
import { useEffect, useState } from 'react';
import { InitStatus } from '../screens/Application/hooks';
import decode from 'jwt-decode';
import { useVariantContext, VariantName } from '../components/VariantContext';
import { AuthTokenResponseDTO } from '../api/types';
import { getCurrentISODate } from './misc';

const SESSION_STORAGE_KEY = 'visitor:portal:session';

export const getSessionFromStorage = (): SessionDTO | undefined => {
    const storageSession = localStorage.getItem(SESSION_STORAGE_KEY);
    if (storageSession) {
        try {
            return JSON.parse(storageSession) as SessionDTO;
        } catch (e) {
            console.error('Could not parse session from storage.');
        }
    }

    return undefined;
};

export const getClaimsFromSession = () => {
    const session = getSessionFromStorage();
    if (!session) return undefined;
    return decode(session.access_token) as SessionClaims;
};

export const saveSessionToStorage = (tokenResponse: AuthTokenResponseDTO, variant: VariantName) => {
    if (!tokenResponse.access_token || !tokenResponse.expires_in || !tokenResponse.refresh_token) {
        throw new Error('Invalid Session');
    }

    const session = createSession(tokenResponse, variant);
    localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(session));
};

const createSession = (tokenResponse: AuthTokenResponseDTO, variant: VariantName): SessionDTO => {
    const currentISODate = getCurrentISODate();
    const expiresInSeconds = tokenResponse.expires_in - 120; // Offset two minutes
    return {
        ...tokenResponse,
        expiresAt: currentISODate.add(expiresInSeconds, 'seconds').toISOString(),
        variant,
    };
};

export const useSessionInitialisation = (
    params: InitialisationParams,
    options: SessionOptions = {}
) => {
    const [sessionInitialised, setSessionInitialised] = useState(InitStatus.Pending);

    const { variant } = useVariantContext();
    const authToken = useAuthTokenMutation();

    const sessionComplete = () => setSessionInitialised(InitStatus.Complete);
    const sessionError = () => setSessionInitialised(InitStatus.Error);

    useEffect(() => {
        if (params.mtoken) {
            const authenticateParameters = {
                masterToken: params.mtoken,
                variant,
            };

            authToken.mutateAsync(authenticateParameters).then(sessionComplete).catch(sessionError);
        } else {
            const storageSession = getSessionFromStorage();
            if (storageSession === undefined) return sessionError();
            sessionComplete();
        }
    }, []);

    useEffect(() => {
        switch (sessionInitialised) {
            case InitStatus.Error: {
                window.location.href = options.redirectUrl || `/login/`;
                return;
            }
            case InitStatus.Complete: {
                window.history.replaceState({}, '', window.location.pathname);
            }
        }
    }, [sessionInitialised]);

    return sessionInitialised;
};
