import Styled from 'styled-components';
import { FlexRow } from "../../../../../assets/styles";

export const FooterContainer = Styled.footer`
    ${FlexRow};
    padding: 1.5rm;
    background-color: var(--background-light-500);
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    padding: 0 2rem;

    .euiText, .euiLink {
        font-size: 0.8rem;
    }
`;

export const FooterLinks = Styled.div`
    ${FlexRow};
    justify-content: flex-end;
    a {
        font-size: 0.8rem;
        color: white !important;
        margin-left: 1.5rem;
        &:hover {
            color: var(--text-subdued) !important;
        }
    }
`;
