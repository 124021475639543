import React, { Fragment, useState } from "react";
import {
    ChapterName,
    OrgDetails,
    OrgSelectionContainer,
    OrgSelectionModal,
    OrgSelectionPopover,
    RegionName
} from "./assets/styles";
import { EuiLoadingContent } from "@elastic/eui";
import { useGetChapterDetails } from "../../../../api/queries";
import QueryError from "../../../QueryError";
import OrgSelectionMenu from "./lib/OrgSelectionMenu";
import { OrgSelectionProps } from "./types";
import Icon from "../../../Icon";

const OrgSelection = (props: OrgSelectionProps) => {

    const { chapterId, onChangeChapter, tabIndex, disabled, type, useModalDisplay, suppressPlannedChapters } = props;
    const [showOrgPopup, setShowOrgPopup] = useState(false);
    const chapterDetailQuery = useGetChapterDetails(chapterId);

    if (chapterDetailQuery.isError) {
        return (
            <QueryError
                error="Could not load chapter details"
                onRetry={chapterDetailQuery.refetch}
            />
        );
    }

    if (!chapterDetailQuery.isSuccess) {
        return (
            <OrgSelectionContainer>
                <EuiLoadingContent lines={2} />
            </OrgSelectionContainer>
        );
    }

    const onSelectChapter = (chapterId: string) => {
        setShowOrgPopup(false);
        onChangeChapter(chapterId);
    };

    const chapterDetails = chapterDetailQuery.data;
    const chapterSelectionElement = (
        <OrgSelectionContainer tabIndex={tabIndex} $disabled={disabled} $open={showOrgPopup}
                               onClick={() => setShowOrgPopup(true)}>
            <OrgDetails>
                <ChapterName>{chapterDetails.name}</ChapterName>
                <RegionName>{chapterDetails.regionName}</RegionName>
            </OrgDetails>
            <Icon type="arrowDown" />
        </OrgSelectionContainer>
    );

    const menuComponent = (
        <OrgSelectionMenu
            type={type}
            chapterDetails={chapterDetails}
            onSelectChapter={onSelectChapter}
            suppressPlannedChapters={suppressPlannedChapters}
        />
    );

    if (useModalDisplay) {
        return (
            <Fragment>
                {chapterSelectionElement}
                {showOrgPopup && (
                    <OrgSelectionModal onClose={() => setShowOrgPopup(false)}>
                        {menuComponent}
                    </OrgSelectionModal>
                )}
            </Fragment>

        );
    }

    return (
        <OrgSelectionPopover
            button={chapterSelectionElement}
            isOpen={showOrgPopup}
            closePopover={() => setShowOrgPopup(false)}>
            {menuComponent}
        </OrgSelectionPopover>
    );

};

export default OrgSelection;
