import { QueryKey, useMutation, useQuery, UseQueryOptions } from 'react-query';
import {
    authenticateClient,
    bulkDeleteRegistrations,
    bulkEditVisitorRegistrations,
    createVisitorRegistration,
    editVisitorRegistration,
    getOrgChapters,
    getOrgCountries,
    getOrgRegions,
    getCategories,
    getChapterDetails,
    getChapterDisallowedDates,
    getChapterInvitedBy,
    getChapterMenuAccess,
    getCountries,
    getLanguageDetails,
    getMarkAttendanceStatus,
    getTranslations,
    getUserLanguages,
    getUserProfile,
    getUserProfileImage,
    getUserTitles,
    getAdminVisitorRegistration,
    getVisitorSubTypes,
    markRegistrationAttended,
    searchCrossChapter,
    searchVisitorRegistrations,
    getVisitorSignupRegistration,
    updateVisitorSignupRegistration,
    createCredentials,
    RegistrationType,
    refreshToken,
} from './endpoints';
import { saveSessionToStorage } from '../utils/session';
import {
    AdminOrgChaptersResponseDTO,
    AdminOrgCountriesResponseDTO,
    AdminOrgDTO,
    AdminOrgRegionsResponseDTO,
    CategoriesResponseDTO,
    ChapterDetailResponseDTO,
    CountriesResponseDTO,
    CreateVisitorRegistrationRequestDTO,
    CrossChapterSearchRequestDTO,
    CrossChapterSearchResponseDTO,
    EditVisitorRegistrationRequestDTO,
    InvitedByResponseDTO,
    LanguageDetailsResponseDTO,
    MarkAttendanceStatus,
    MarkAttendanceStatusMap,
    MarkAttendedStatusResponseDTO,
    ResponseEnvelopeDTO,
    SearchRegistrationCriteriaDTO,
    TranslationsMap,
    TranslationsResponseDTO,
    UserLanguagesResponseDTO,
    UserProfileResponseDTO,
    UserTitlesResponseDTO,
    AdminVisitorRegistrationDTO,
    VisitorSubTypeResponseDTO,
    UpdateSignUpRegistrationParams,
    VisitorSignUpRegistrationDTO,
    CreateCredentialsRequestDTO,
    APIError,
    MarkAttendedRecords,
    MarkAttendedResponseDTO,
} from './types';
import { Response } from '../utils/http';
import { SearchCriteriaOptions } from '../screens/VisitorRegistrations/hooks';
import { UseMutationOptions } from 'react-query/types/react/types';
import { useAppContext } from '../components/ApplicationContext';
import { AxiosError } from 'axios';
import { getMeetingFormat } from './endpoints';

export const queryKeys = {
    authToken: () => ['authenticate-token'] as const,
    refreshToken: () => ['refresh-token'] as const,
    chapterDetails: (chapterId?: string) => ['chapter-details', chapterId] as const,
    adminOrgCountries: (type: RegistrationType, filter?: string) =>
        ['admin', 'org-countries', type, filter] as const,
    adminOrgRegions: (type: RegistrationType, countryId?: string, filter?: string) =>
        ['admin', 'org-regions', type, countryId, filter] as const,
    adminOrgChapters: (
        type: RegistrationType,
        regionId?: string,
        filter?: string,
        suppressPlannedChapters?: boolean
    ) => ['admin', 'org-chapters', type, regionId, filter, suppressPlannedChapters] as const,
    searchRegistrations: () => ['search-registrations'],
    searchRegistrationsCriteria: (criteria?: SearchRegistrationCriteriaDTO) =>
        [...queryKeys.searchRegistrations(), criteria] as const,
    userProfile: () => ['user-profile'] as const,
    userProfileImage: () => ['user-profile-image'] as const,
    userTitles: () => ['user-titles'] as const,
    userLanguages: () => ['user-languages'] as const,
    baseVisitorRegistration: 'visitor-registrations',
    visitorRegistration: (registrationId: string) =>
        [queryKeys.baseVisitorRegistration, registrationId] as const,
    updateVisitorRegistration: (registrationId?: string) =>
        ['update-visitor-registrations', registrationId] as const,
    baseAdminVisitorRegistration: 'admin-visitor-registrations',
    adminVisitorRegistration: (registrationId: string) =>
        [queryKeys.baseAdminVisitorRegistration, registrationId] as const,
    countries: () => ['countries'] as const,
    translations: () => ['translations'] as const,
    categories: (countryId: number, locale?: string) => ['categories', countryId, locale] as const,
    visitorSubTypes: () => ['visitor-sub-types'] as const,
    createVisitorRegistration: () => ['create-visitor-registration'] as const,
    editVisitorRegistration: () => ['edit-visitor-registration'] as const,
    bulkEditVisitorRegistrations: () => ['bulk-edit-visitor-registrations'] as const,
    deleteRegistrations: () => ['delete-visitor-registrations'] as const,
    languageDetails: (localeCode?: string) => ['language', localeCode] as const,
    chapterInvitedBy: (chapterId?: string) => ['chapter-invited-by', chapterId] as const,
    markAttended: () => ['mark-attended'] as const,
    chapterMenuAccess: (chapterId?: string) => ['chapter-menu-access', chapterId] as const,
    meetingFormat: (chapterId?: string, month?: number, year?: number) =>
        ['meeting-format', chapterId, month, year] as const,
    markAttendedStatus: (registrationIds?: Array<number>) =>
        ['mark-attendance-status', registrationIds] as const,
    chapterDisallowedDates: (chapterId?: string) =>
        ['chapter-disallowed-dates', chapterId] as const,
    searchCrossChapter: (chapterId?: string) => ['search-cross-chapter', chapterId],
    searchCrossChapterCriteria: (chapterId?: string, criteria?: CrossChapterSearchRequestDTO) =>
        [...queryKeys.searchCrossChapter(chapterId), criteria] as const,
    createCredentials: () => ['create-credentials'] as const,
};

export const useAuthTokenMutation = () => {
    return useMutation({
        mutationKey: queryKeys.authToken(),
        mutationFn: authenticateClient,
        onSuccess: (response, parameters) => {
            if (!response.data.content) {
                throw new Error('Invalid Session received from authenticate token call.');
            }
            saveSessionToStorage(response.data.content, parameters.variant);
        },
    });
};

export type GetChapterDetailsOptions = UseQueryOptions<
    Response<ResponseEnvelopeDTO<ChapterDetailResponseDTO>>,
    unknown,
    ChapterDetailResponseDTO,
    ReturnType<typeof queryKeys.chapterDetails>
>;

export const useGetChapterDetails = (chapterId: string, options?: GetChapterDetailsOptions) => {
    return useQuery({
        queryKey: queryKeys.chapterDetails(chapterId),
        select: (response) => response.data.content,
        queryFn: () => getChapterDetails(chapterId),
        staleTime: 1000 * 60 * 15, // 15 minutes,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        ...options,
    });
};

export type GetAdminOrgsQueryOptions<OrgResponseDTO, OrgQueryKey extends QueryKey> =
    UseQueryOptions<
        Response<ResponseEnvelopeDTO<OrgResponseDTO>>,
        unknown,
        AdminOrgDTO[],
        OrgQueryKey
    >;

export type OrgCountriesQueryOptions = GetAdminOrgsQueryOptions<
    AdminOrgCountriesResponseDTO,
    ReturnType<typeof queryKeys.adminOrgCountries>
>;

export const useGetOrgCountries = (
    type: RegistrationType,
    searchFilter?: string,
    options?: OrgCountriesQueryOptions
) => {
    return useQuery({
        queryKey: queryKeys.adminOrgCountries(type, searchFilter),
        select: (response) => response.data.content.countries,
        queryFn: () => getOrgCountries(type, searchFilter),
        staleTime: 1000 * 60 * 10, // 10 minutes,
        keepPreviousData: true,
        ...options,
    });
};

export type OrgRegionsQueryOptions = GetAdminOrgsQueryOptions<
    AdminOrgRegionsResponseDTO,
    ReturnType<typeof queryKeys.adminOrgRegions>
>;

export const useGetOrgRegions = (
    type: RegistrationType,
    countryId?: string,
    searchFilter?: string,
    options?: OrgRegionsQueryOptions
) => {
    return useQuery({
        queryKey: queryKeys.adminOrgRegions(type, countryId!, searchFilter),
        select: (response) => response.data.content.regions,
        queryFn: () => getOrgRegions(type, countryId!, searchFilter),
        staleTime: 1000 * 60 * 10, // 10 minutes,
        enabled: countryId !== undefined,
        keepPreviousData: true,
        ...options,
    });
};

export type OrgChaptersQueryOptions = GetAdminOrgsQueryOptions<
    AdminOrgChaptersResponseDTO,
    ReturnType<typeof queryKeys.adminOrgChapters>
> & {
    suppressPlannedChapters?: boolean;
};

export const useGetOrgChapters = (
    type: RegistrationType,
    regionId?: string,
    searchFilter?: string,
    options?: OrgChaptersQueryOptions
) => {
    const { suppressPlannedChapters, ...queryOptions } = options || {};
    return useQuery({
        queryKey: queryKeys.adminOrgChapters(
            type,
            regionId!,
            searchFilter,
            suppressPlannedChapters
        ),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        select: (response) => response.data.content.chapters,
        queryFn: () => getOrgChapters(type, regionId!, searchFilter, suppressPlannedChapters),
        staleTime: 1000 * 60 * 10, // 10 minutes,
        enabled: regionId !== undefined,
        keepPreviousData: true,
        ...queryOptions,
    });
};

export const useSearchRegistrations = (searchOptions?: SearchCriteriaOptions) => {
    return useQuery({
        queryKey: queryKeys.searchRegistrationsCriteria(searchOptions?.criteria),
        queryFn: () => searchVisitorRegistrations(searchOptions!.criteria!),
        enabled: searchOptions !== undefined,
        select: (response) => response.data.content,
        keepPreviousData: true,
    });
};

export type GetProfileOptions = Omit<
    UseQueryOptions<
        Response<ResponseEnvelopeDTO<UserProfileResponseDTO>>,
        unknown,
        UserProfileResponseDTO,
        ReturnType<typeof queryKeys.userProfile>
    >,
    'select'
>;

export const useProfile = (options?: GetProfileOptions) => {
    return useQuery({
        queryKey: queryKeys.userProfile(),
        queryFn: getUserProfile,
        staleTime: 1000 * 60 * 30, // 30 Minutes,
        select: (response) => response.data.content,
        ...options,
    });
};

export const useProfileImage = () => {
    return useQuery({
        queryKey: queryKeys.userProfileImage(),
        queryFn: getUserProfileImage,
        staleTime: 1000 * 60 * 30, // 30 Minutes
        select: (response) => {
            return response.data.content.contact.profileImageId.value;
        },
    });
};

export function useVisitorSignUpRegistration(registrationId: string) {
    return useQuery({
        queryKey: queryKeys.visitorRegistration(registrationId),
        queryFn: () => getVisitorSignupRegistration(registrationId),
        staleTime: 1000 * 60 * 5, // 5 Minutes,
        select: (response) => response.data.content,
    });
}

export function useAdminVisitorRegistration<DataType = AdminVisitorRegistrationDTO>(
    registrationId: string
) {
    return useQuery({
        queryKey: queryKeys.adminVisitorRegistration(registrationId),
        queryFn: () => getAdminVisitorRegistration(registrationId),
        staleTime: 1000 * 60 * 5, // 5 Minutes,
        select: (response) => response.data.content,
    });
}

type UserTitlesOptions<DataType> = UseQueryOptions<
    Response<ResponseEnvelopeDTO<UserTitlesResponseDTO>>,
    unknown,
    DataType,
    ReturnType<typeof queryKeys.userTitles>
>;

export function useUserTitles<Data>(options?: UserTitlesOptions<Data>) {
    return useQuery({
        queryKey: queryKeys.userTitles(),
        queryFn: getUserTitles,
        staleTime: Infinity,
        ...options,
    });
}

type UserLanguagesOptions<DataType> = UseQueryOptions<
    Response<ResponseEnvelopeDTO<UserLanguagesResponseDTO>>,
    unknown,
    DataType,
    ReturnType<typeof queryKeys.userLanguages>
>;

export function useUserLanguages<Data>(options?: UserLanguagesOptions<Data>) {
    return useQuery({
        queryKey: queryKeys.userLanguages(),
        queryFn: getUserLanguages,
        staleTime: Infinity,
        ...options,
    });
}

type CountriesOptions<DataType> = UseQueryOptions<
    Response<ResponseEnvelopeDTO<CountriesResponseDTO>>,
    unknown,
    DataType,
    ReturnType<typeof queryKeys.countries>
>;

export function useCountries<Data>(options?: CountriesOptions<Data>) {
    return useQuery({
        queryKey: queryKeys.countries(),
        queryFn: getCountries,
        staleTime: Infinity,
        ...options,
    });
}

type TranslationsOptions = UseQueryOptions<
    Response<ResponseEnvelopeDTO<TranslationsResponseDTO>>,
    unknown,
    TranslationsMap,
    ReturnType<typeof queryKeys.translations>
>;

export const useTranslations = (localeCode: string, options?: TranslationsOptions) => {
    return useQuery({
        queryKey: queryKeys.translations(),
        queryFn: () => getTranslations(localeCode),
        select: (response) => response.data.content.translations,
        staleTime: 1000 * 60 * 60, // 1 Hour,
        ...options,
    });
};

type CategoriesOptions<DataType> = UseQueryOptions<
    Response<ResponseEnvelopeDTO<CategoriesResponseDTO>>,
    unknown,
    DataType,
    ReturnType<typeof queryKeys.categories>
>;

export function useCategories<Data>(
    countryId: number,
    locale?: string,
    options?: CategoriesOptions<Data>
) {
    return useQuery({
        queryKey: queryKeys.categories(countryId, locale),
        queryFn: () => getCategories(countryId, locale),
        staleTime: 1000 * 60 * 10, // 10 Minutes,
        ...options,
    });
}

type VisitorSubTypeOptions<DataType> = UseQueryOptions<
    Response<ResponseEnvelopeDTO<VisitorSubTypeResponseDTO>>,
    unknown,
    DataType,
    ReturnType<typeof queryKeys.visitorSubTypes>
>;

export function useVisitorSubTypes<DataType>(options?: VisitorSubTypeOptions<DataType>) {
    return useQuery({
        queryKey: queryKeys.visitorSubTypes(),
        queryFn: getVisitorSubTypes,
        staleTime: 1000 * 60 * 30, // 30 Minutes,
        ...options,
    });
}

type CreateRegistrationMutationOptions = UseMutationOptions<
    Response<ResponseEnvelopeDTO<AdminVisitorRegistrationDTO>>,
    AxiosError<APIError>,
    CreateVisitorRegistrationRequestDTO,
    unknown
>;

export const useCreateRegistrationMutation = (options?: CreateRegistrationMutationOptions) => {
    const { userProfile } = useAppContext();
    return useMutation({
        mutationKey: queryKeys.createVisitorRegistration(),
        mutationFn: (registration: CreateVisitorRegistrationRequestDTO) =>
            createVisitorRegistration(userProfile, registration),
        ...options,
    });
};

type EditRegistrationMutationOptions = UseMutationOptions<
    Response<ResponseEnvelopeDTO<AdminVisitorRegistrationDTO>>,
    AxiosError<APIError>,
    EditVisitorRegistrationRequestDTO,
    unknown
>;

export const useEditRegistrationMutation = (options?: EditRegistrationMutationOptions) => {
    const { userProfile } = useAppContext();
    return useMutation({
        mutationKey: queryKeys.editVisitorRegistration(),
        mutationFn: (registration: EditVisitorRegistrationRequestDTO) =>
            editVisitorRegistration(userProfile, registration),
        ...options,
    });
};

type DeleteRegistrationsMutationOptions = UseMutationOptions<
    Response<ResponseEnvelopeDTO<unknown>>,
    unknown,
    number[],
    unknown
>;

export const useDeleteRegistrationsMutation = (options?: DeleteRegistrationsMutationOptions) => {
    return useMutation({
        mutationKey: queryKeys.deleteRegistrations(),
        mutationFn: (registrationIds: Array<number>) => bulkDeleteRegistrations(registrationIds),
        ...options,
    });
};

type LanguageDetailsOptions = UseQueryOptions<
    Response<ResponseEnvelopeDTO<LanguageDetailsResponseDTO>>,
    unknown,
    LanguageDetailsResponseDTO,
    ReturnType<typeof queryKeys.languageDetails>
>;

export const useLanguageDetails = (localeCode: string, options?: LanguageDetailsOptions) => {
    return useQuery({
        queryKey: queryKeys.languageDetails(localeCode),
        queryFn: () => getLanguageDetails(localeCode),
        select: (response) => response.data.content,
        staleTime: Infinity,
        ...options,
    });
};

type ChapterInvitedByOptions<DataType> = UseQueryOptions<
    Response<ResponseEnvelopeDTO<InvitedByResponseDTO>>,
    unknown,
    DataType,
    ReturnType<typeof queryKeys.chapterInvitedBy>
>;

export const useChapterInvitedBy = <DataType>(
    chapterId: string,
    options?: ChapterInvitedByOptions<DataType>
) => {
    return useQuery({
        queryKey: queryKeys.chapterInvitedBy(chapterId),
        queryFn: () => getChapterInvitedBy(chapterId),
        staleTime: 1000 * 60 * 30, // 30 ,
        ...options,
    });
};

type BulkEditRegistrationsOptions = UseMutationOptions<
    Array<Response<ResponseEnvelopeDTO<AdminVisitorRegistrationDTO>>>,
    unknown,
    Array<EditVisitorRegistrationRequestDTO>,
    unknown
>;

export const useBulkEditRegistrationsMutation = (options?: BulkEditRegistrationsOptions) => {
    const { userProfile } = useAppContext();
    return useMutation({
        mutationKey: queryKeys.bulkEditVisitorRegistrations(),
        mutationFn: (registrations) => bulkEditVisitorRegistrations(userProfile, registrations),
        ...options,
    });
};

type MarkAttendanceOptions = UseMutationOptions<
    Response<ResponseEnvelopeDTO<MarkAttendedResponseDTO>>,
    unknown,
    MarkAttendedRecords,
    unknown
>;

export const useMarkAttendedMutation = (options?: MarkAttendanceOptions) => {
    return useMutation({
        mutationKey: queryKeys.markAttended(),
        mutationFn: (markAttendanceRecords) => markRegistrationAttended(markAttendanceRecords),
        ...options,
    });
};

export const useChapterMenuAccess = (chapterId: string) => {
    return useQuery({
        queryKey: queryKeys.chapterMenuAccess(chapterId),
        queryFn: () => getChapterMenuAccess(chapterId),
        select: (response) => response.data.content.chapters[0].allowed_actions,
        staleTime: 1000 * 60 * 60, // 1 Hour
    });
};
/* eslint-disable no-debugger */
export const useMeetingFormat = (
    chapterId?: string,
    month?: number,
    year?: number,
    locale?: string
) => {
    return useQuery({
        queryKey: queryKeys.meetingFormat(chapterId, month, year),
        queryFn: () => getMeetingFormat(chapterId, month, year, locale),
        select: (response) => response.data.content,
        staleTime: 1000 * 60 * 60, // 1 Hour
    });
};

type MarkAttendanceStatusOptions = Omit<
    UseQueryOptions<
        Response<ResponseEnvelopeDTO<MarkAttendedStatusResponseDTO>>,
        unknown,
        MarkAttendanceStatusMap,
        ReturnType<typeof queryKeys.markAttendedStatus>
    >,
    'select'
>;

export function useMarkingAttendanceStatus(
    registrationIds: Array<number>,
    options?: MarkAttendanceStatusOptions
) {
    return useQuery({
        queryKey: queryKeys.markAttendedStatus(registrationIds),
        queryFn: () => getMarkAttendanceStatus(registrationIds),
        select: (response) => mapToAttendanceMap(response.data.content),
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        ...options,
    });
}

const mapToAttendanceMap = (registrations: Array<AdminVisitorRegistrationDTO>) => {
    return registrations.reduce((accumulator, registration) => {
        accumulator[registration.id.toString()] =
            registration.markingAttendedStatus || MarkAttendanceStatus.Pending;
        return accumulator;
    }, {} as MarkAttendanceStatusMap);
};

export const useChapterDisallowedDates = (chapterId: string) => {
    return useQuery({
        queryKey: queryKeys.chapterDisallowedDates(chapterId),
        queryFn: () => getChapterDisallowedDates(chapterId),
        select: (response) => response.data.content.disallowedDates,
        staleTime: 1000 * 60 * 30,
    });
};

type SearchCrossChapterOptions = UseQueryOptions<
    Response<ResponseEnvelopeDTO<CrossChapterSearchResponseDTO>>,
    unknown,
    CrossChapterSearchResponseDTO,
    ReturnType<typeof queryKeys.searchCrossChapterCriteria>
>;

export const useSearchCrossChapter = (
    chapterId: string,
    criteria?: CrossChapterSearchRequestDTO,
    options?: SearchCrossChapterOptions
) => {
    return useQuery({
        queryKey: queryKeys.searchCrossChapterCriteria(chapterId, criteria),
        queryFn: () => searchCrossChapter(chapterId, criteria!),
        select: (response) => response.data.content,
        keepPreviousData: true,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        ...options,
    });
};

type UpdateSignUpRegistrationOptions = UseMutationOptions<
    Response<ResponseEnvelopeDTO<VisitorSignUpRegistrationDTO>>,
    unknown,
    UpdateSignUpRegistrationParams,
    unknown
>;

export const useUpdateSignUpRegistration = (options?: UpdateSignUpRegistrationOptions) => {
    return useMutation({
        mutationKey: queryKeys.updateVisitorRegistration(),
        mutationFn: (parameters: UpdateSignUpRegistrationParams) =>
            updateVisitorSignupRegistration(parameters.registrationId, parameters.registration),
        ...options,
    });
};

type CreateCredentialsMutationOptions = UseMutationOptions<
    Response<ResponseEnvelopeDTO<unknown>>,
    unknown,
    CreateCredentialsRequestDTO,
    unknown
>;

export const useCreateCredentialsMutation = (options?: CreateCredentialsMutationOptions) => {
    return useMutation({
        mutationKey: queryKeys.createCredentials(),
        mutationFn: (credentials: CreateCredentialsRequestDTO) => createCredentials(credentials),
        ...options,
    });
};
