import { useMemo } from 'react';
import { ProviderContext, useSnackbar } from 'notistack';

export type UseNotificationsReturn = Omit<ProviderContext, 'enqueueSnackbar' | 'closeSnackbar'> & {
    queueNotification: ProviderContext['enqueueSnackbar'];
    closeTray: ProviderContext['closeSnackbar'];
};

export const useNotifications = (): UseNotificationsReturn => {
    const snackbar = useSnackbar();
    return useMemo(() => {
        const { enqueueSnackbar, closeSnackbar, ...others } = snackbar;
        return {
            queueNotification: enqueueSnackbar,
            closeTray: closeSnackbar,
            ...others,
        };
    }, [snackbar]);
};
