import React from 'react';
import { FullScreenWrapper, StyledLoadingSpinner } from './assets/styles';
import { LoadingIndicatorProps } from './types';

const LoadingIndicator = ({ fullScreen, ...others }: LoadingIndicatorProps) => {
    if (fullScreen)
        return (
            <FullScreenWrapper>
                <StyledLoadingSpinner {...others} />
            </FullScreenWrapper>
        );

    return <StyledLoadingSpinner {...others} />;
};

export default LoadingIndicator;
