import { getClaimsFromSession, useSessionInitialisation } from '../../utils/session';
import { parse } from 'query-string';
import { InitialisationParams } from '../../types';
import { useEffect, useState } from 'react';
import { getParametersFromStorage, saveParametersToStorage } from '../../utils/parameters';
import {
    useGetChapterDetails,
    useLanguageDetails,
    useProfile,
    useTranslations,
} from '../../api/queries';
import {
    LanguageDetailsResponseDTO,
    TranslationsMap,
    TranslationsResponseDTO,
    UserProfileResponseDTO,
} from '../../api/types';
import { isError, isPending } from './utils';
import { getLanguageDetails } from '../../api/endpoints';

export enum InitStatus {
    Complete,
    Pending,
    Error,
}

export type BootstrapData =
    | [
          InitStatus.Complete,
          {
              localeCode: string;
              parameters?: InitialisationParams;
              translations: TranslationsMap;
              languageDetails: LanguageDetailsResponseDTO;
              userProfile: UserProfileResponseDTO;
          }
      ]
    | [InitStatus.Pending, undefined]
    | [InitStatus.Error, undefined];

export const useBootstrap = (): BootstrapData => {
    const { requestParameters, cachedParameters } = useParameters();

    const sessionStatus = useSessionInitialisation(requestParameters);
    const [, chapterDetails] = useChapterDetailsInitialisation(sessionStatus, cachedParameters);

    const localeCode =
        cachedParameters?.locale || chapterDetails?.defaultLanguage.localeCode || undefined;

    const [translationStatus, translations] = useTranslationsInitialisation(
        sessionStatus,
        localeCode
    );

    const [languageStatus, languageDetails] = useLanguageInitialisation(sessionStatus, localeCode);
    const [profileStatus, profileData] = useProfileInitialisation(sessionStatus);

    if (isPending(sessionStatus, translationStatus, languageStatus, profileStatus)) {
        return [InitStatus.Pending, undefined];
    }

    if (isError(sessionStatus, translationStatus, languageStatus, profileStatus)) {
        return [InitStatus.Error, undefined];
    }

    return [
        InitStatus.Complete,
        {
            localeCode: localeCode!,
            translations: translations!,
            parameters: cachedParameters!,
            languageDetails: languageDetails!,
            userProfile: profileData!,
        },
    ];
};

export const useChapterDetailsInitialisation = (
    sessionStatus: InitStatus,
    parameters?: InitialisationParams
) => {
    const chapterDetailsQuery = useGetChapterDetails(parameters?.chapterId || '1', {
        enabled: sessionStatus === InitStatus.Complete && parameters?.chapterId !== undefined,
    });

    if (chapterDetailsQuery.isError) return [InitStatus.Error, undefined] as const;
    if (!chapterDetailsQuery.isSuccess) return [InitStatus.Pending, undefined] as const;
    return [InitStatus.Complete, chapterDetailsQuery.data] as const;
};

export const useTranslationsInitialisation = (sessionStatus: InitStatus, localeCode?: string) => {
    const translationsQuery = useTranslations(localeCode!, {
        enabled: sessionStatus === InitStatus.Complete && localeCode !== undefined,
    });

    if (translationsQuery.isError) return [InitStatus.Error, undefined] as const;
    if (!translationsQuery.isSuccess) return [InitStatus.Pending, undefined] as const;
    return [InitStatus.Complete, translationsQuery.data, localeCode] as const;
};

export const useProfileInitialisation = (sessionStatus: InitStatus) => {
    const profileQuery = useProfile({
        enabled: sessionStatus === InitStatus.Complete,
    });

    if (profileQuery.isError) return [InitStatus.Error, undefined] as const;
    if (!profileQuery.isSuccess) return [InitStatus.Pending, undefined] as const;

    return [InitStatus.Complete, profileQuery.data] as const;
};

export const useLanguageInitialisation = (sessionStatus: InitStatus, localeCode?: string) => {
    const languageDetailsQuery = useLanguageDetails(localeCode!, {
        queryFn: () => getLanguageDetails(localeCode!),
        enabled: sessionStatus === InitStatus.Complete && localeCode !== undefined,
    });

    if (languageDetailsQuery.isError) return [InitStatus.Error, undefined] as const;
    if (!languageDetailsQuery.isSuccess) return [InitStatus.Pending, undefined] as const;

    return [InitStatus.Complete, languageDetailsQuery.data] as const;
};

const useParameters = () => {
    const requestParameters = parse(window.location.search) as InitialisationParams;
    const cachedParameters = getParametersFromStorage();
    useEffect(() => {
        const { mtoken, ...others } = requestParameters;
        if (mtoken) saveParametersToStorage(others);
    }, []);

    return {
        requestParameters,
        cachedParameters,
    };
};
