// HTTP
export const protocol = `${process.env.REACT_APP_API_PROTOCOL || 'https://'}`;
export const hostname = `${process.env.REACT_APP_API_HOST || 'api.bnitest2.sasyadev.com'}`;
export const port = `${process.env.REACT_APP_API_PORT || '443'}`;
export const context = process.env.REACT_APP_API_CONTEXT;
export const domain = `${protocol}${hostname}:${port}${context ? `/${context}/` : '/'}`;
export const requestTimeout = Number(`${process.env.REACT_APP_HTTP_REQUEST_TIMEOUT_MS || 10000}`);

// Client
export const connectClientId = `${process.env.REACT_APP_CONNECT_CLIENT_ID || 'VISITOR_PORTAL'}`;
export const connectClientSecret = `${
    process.env.REACT_APP_CONNECT_CLIENT_SECRET || 'L2bCG6%wFASJ%*y9hU+@'
}`;

export const corporateClientId = `${
    process.env.REACT_APP_CORPORATE_CLIENT_ID || 'CC_VISITOR_PORTAL'
}`;
export const corporateClientSecret = `${
    process.env.REACT_APP_CORPORATE_CLIENT_SECRET || 'nSwyPjgX4*LCa5EJL%!4'
}`;

// Preferences
export const preferencePrefix = 'bni-visitor-portal';
