import { InitStatus } from './hooks';
import { InitialisationParams } from '../../types';
import { notEmpty } from '../../utils/misc';

export const isBootstrapComplete = (status: InitStatus) => status === InitStatus.Complete;

export const isPending = (...statuses: InitStatus[]) => {
    return statuses.some((status) => status === InitStatus.Pending);
};

export const isError = (...statuses: InitStatus[]) => {
    return statuses.some((status) => status === InitStatus.Error);
};

export const selfRegistrationParametersValid = (parameters: InitialisationParams) => {
    return (
        notEmpty(parameters.chapterId) &&
        notEmpty(parameters.step) &&
        notEmpty(parameters.haspassword) &&
        notEmpty(parameters.recordId)
    );
};
