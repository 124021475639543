import Styled from 'styled-components';
import { EuiFieldSearch, EuiText } from "@elastic/eui";
import { FlexColumn, FlexRow } from "../../../../../../../assets/styles";
import Button from "../../../../../../Button";
import { getDirection } from "../../../../../../../utils/misc";

export const OrgMenusContainer = Styled.div.attrs({
    className: 'org-menus'
})`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    direction: ${() => getDirection()};
`;

export const OrgPanelsContainer = Styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    width: 100%;
    padding: 1rem 1rem;
`;

export const SelectedChapterContainer = Styled.div`
    ${FlexColumn};
    flex: 1;
`;

export const FooterText = Styled(EuiText)`
    font-size: 0.9em;
    line-height: 1.5em;
    font-weight: 400;
`;

export const SelectedChapterText = Styled(FooterText)`
    color: var(--primary);
    font-weight: 600;
`;

export const LoadingChapterText = Styled(FooterText)`
    font-weight: 400;
    color: var(--text-subdued);
`;

export const OrgMenuFooter = Styled.div`
    ${FlexRow};
    margin-top: 0.2rem;
    padding: 1rem;
    border-top: 1px solid var(--background-light-200);
    justify-content: space-between;
`;
export const OrgMenuPanelContainer = Styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    width: 250px;
    max-height: 420px;
    position: relative;
`;

export const PanelHeader = Styled.div`
    ${FlexColumn};
    background: white;
    display: block;
    z-index: 1;
`;

export const OrgMenuContents = Styled.div`

    overflow-y: auto;
    padding-top: 0.5rem;

    .euiListGroupItem__button {
        padding: 0.3sem;
        font-size: 0.9em;
    }
    .euiListGroupItem {

        margin-top: 0 !important;
        border-left: 2px solid white;

        &:not(.section):hover {
            border-left: 2px solid var(--primary);
            font-weight: 600;
        }


        &.section {
            pointer-events: none;
            border-left: var(--background-light-200);
            background-color: var(--background-light-200);
            .euiListGroupItem__label {
                font-size: 0.9rem;
                font-weight: 600;
            }
        }
        &.section:hover {
            background-color: var(--background-light-200);
        }

        &.selected {
            border-left: 2px solid var(--primary);
            background-color: var(--primary);
            color: white;
            font-weight: 600;
        }


        &.euiListGroupItem-isClickable:hover .euiListGroupItem__button,
        & .euiListGroupItem__button:hover,
        & .euiListGroupItem__button:focus {
            text-decoration: none !important;
        }

        &:hover, &:focus, &:visited {
            .euiListGroupItem__button {
                text-decoration: none !important;
            }
        }
    }

    .euiLoadingContent__singleLine {
        height: 35px;
    }


`;

export const OrgPlaceholderContainer = Styled.div`

    .euiLoadingContent__singleLine {
        height: 40px;
    }
`;

export const MenuLabel = Styled(EuiText)`
    font-size: 0.9em;
    font-weight: 600;
`;

export const FilterOrgsInput = Styled(EuiFieldSearch).attrs({
    compressed: false,
    autocomplete: 'none',
    incremental: true
})`
    padding-bottom: 0.5em;

`;

export const LoadingText = Styled(MenuLabel)`
    color: var(--text-subdued);
    font-weight: 400;
    margin-left: 0.5em;
`;

export const HeaderTextContainer = Styled.div`
    ${FlexRow};
`;

export const ResetButton = Styled(Button).attrs({
    subdued: true
})`
    margin-right: 1rem;
`;
