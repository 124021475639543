import React, { Fragment } from 'react';
import { ApplicationLayoutContainer, Main } from './assets/styles';
import { LayoutProps } from './types';
import Header from 'components/Header';
import Footer from './lib/Footer';

const Layout = ({ children, noFooter, disableOrgSelection }: LayoutProps) => {
    return (
        <Fragment>
            <ApplicationLayoutContainer>
                <Header disableOrgSelection={disableOrgSelection} />
                <Main>{children}</Main>
                {!noFooter && <Footer />}
            </ApplicationLayoutContainer>
        </Fragment>
    );
};

export default Layout;
