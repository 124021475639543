import Styled, { css } from 'styled-components';
import { EuiLink, EuiText } from '@elastic/eui';

export const QueryErrorContainer = Styled.div.attrs({
    className: 'query-error',
})`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    background-color: rgba(255, 29, 29, 0.11);
    padding: 0.4rem 1rem;
    height: 100%;
    border-radius: 0.3rem;
`;

const BaseText = css`
    font-size: 0.8rem !important;
    lint-height: 1rem;
`;

export const ErrorText = Styled(EuiText)`
    ${BaseText};
`;

export const RetryLink = Styled(EuiLink)`
    ${BaseText};
    margin-left: 0.6rem;
    font-weight: 600;
    color: darkred !important;
`;
