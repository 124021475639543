import { preferencePrefix } from './config';

export const getPreferenceStorageKey = (name: string) => {
    return `${preferencePrefix}-${name}`;
};

export const setPreference = <Data>(name: string, data: Data) => {
    localStorage.setItem(getPreferenceStorageKey(name), JSON.stringify(data));
};

export const getPreference = <Data>(name: string) => {
    const storageData = localStorage.getItem(getPreferenceStorageKey(name));
    if (storageData) {
        return JSON.parse(storageData) as Data;
    }
    return undefined;
};

export const deletePreference = (name: string) => {
    localStorage.removeItem(getPreferenceStorageKey(name));
};

export const deleteAllPreferences = () => {
    for (const key in localStorage) {
        if (key.startsWith(preferencePrefix)) {
            localStorage.removeItem(key);
        }
    }
};
